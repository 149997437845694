@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Josefin Sans", sans-serif !important;
}
:root {
  --white: #fff;
  --black: #000;
  --themeclr: #033e30;
  --dark-gray: #202020;
}
.lh-30 {
  line-height: 30px;
}
.lh-29 {
  line-height: 29px;
}
.lh-28 {
  line-height: 28px;
}
.f-11 {
  font-size: 11px;
}
.f-12 {
  font-size: 12px;
}
.f-13 {
  font-size: 13px;
}
.f-14 {
  font-size: 14px;
}
.f-15 {
  font-size: 15px;
}
.f-16 {
  font-size: 16px;
}
.fw-400 {
  font-weight: 400;
}
.fw-700 {
  font-weight: 700;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-800 {
  font-weight: 800;
}

.dark-gray {
  color: var(--dark-gray);
}
.cmn-greenBtn {
  background-color: var(--themeclr) !important;
  color: var(--white) !important;
  border: 1px solid var(--themeclr) !important;
  box-shadow: none !important;
  outline: none !important;
}
.cmn-greenBtn:hover {
  background-color: transparent !important;
  color: var(--themeclr) !important;
  border: 1px solid var(--themeclr) !important;
}

.cmn-greenBtn-bdr {
  background-color: transparent !important;
  color: var(--themeclr) !important;
  border: 1px solid var(--themeclr) !important;
  box-shadow: none !important;
  outline: none !important;
}
.cmn-greenBtn-bdr:hover {
  background-color: var(--themeclr) !important;
  color: var(--white) !important;
  border: 1px solid var(--themeclr) !important;
}

.cmn-grayBtn {
  background-color: #f7f7f7 !important;
  color: #343434 !important;
  border: 1px solid #707070 !important;
  box-shadow: none !important;
  outline: none !important;
}
.cmn-grayBtn:hover {
  background-color: var(--themeclr) !important;
  color: var(--white) !important;
  border: 1px solid var(--themeclr) !important;
}

.cmn-grayBtn1 {
  background-color: #eaeef0 !important;
  color: #373737 !important;
  border: 1px solid transparent !important;
  box-shadow: none !important;
  outline: none !important;
}
.cmn-grayBtn1:hover {
  background-color: transparent !important;
  color: #373737 !important;
  border: 1px solid var(--themeclr) !important;
}

.cmn-darkBinkBtn {
  background-color: #f80d38 !important;
  color: var(--white) !important;
  border: 1px solid #f80d38 !important;
  box-shadow: none !important;
  outline: none !important;
}
.cmn-darkBinkBtn:hover {
  background-color: transparent !important;
  color: #f80d38 !important;
  border: 1px solid #f80d38 !important;
}

.dashboard_sec {
  font-family: "Poppins", sans-serif;
}

/* css */
.header {
  background-color: #e5f8f4;
}
.header .custom-container.container {
  max-width: 90%;
}
.header .logo {
  width: 90px;
}
.header .custom-nav .header-textList a {
  color: var(--themeclr);
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
}
.header .custom-nav .header-textList a:hover {
  color: var(--black);
}
.header .login-btn {
  border-radius: 3px;
  font-size: 13px;
  font-weight: 500;
  padding: 4px 20px;
}

.header .register-btn {
  border-radius: 3px;
  font-size: 13px;
  font-weight: 500;
  padding: 4px 20px;
}

.header-search {
  position: relative;
}
.header-search input {
  border: 1px solid #40ac92;
  border-radius: 4px;
  outline: none;
  height: 32px;
  padding-left: 40px;
  color: #48776e;
  font-weight: 500;
  width: 240px;
  font-size: 14px;
}

.header-search .search-logo {
  width: 22px;
  height: 22px;
  position: absolute;
  left: 9px;
  top: 6px;
}
.headerContents {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  gap: 20px;
}

.headerContents.false {
  gap: 80px;
}

.header .nav-collap .navbar-nav {
  width: 100%;
}

.headerContents .header-textList {
  display: flex;
  align-items: center;
  gap: 10px;
}

.headerContents .header-textList.false {
  gap: 25px;
}

.headerContents .header-btns {
  display: flex;
  align-items: center;
  gap: 10px;
}
.headerContents .bell-icon {
  width: 24px;
}
.headerContents .srch_logo {
  width: 24px;
  cursor: pointer;
}

.header .custom-nav .navbar-toggler {
  padding: 3px 10px;
  box-shadow: none;
}
.header .custom-nav .navbar-toggler .navbar-toggler-icon {
  width: 1em;
  height: 1em;
}

/* footer */
.footer {
  background-color: var(--white);
}
.footer .footer-content {
  padding: 40px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer .heading-txt {
  color: var(--themeclr);
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
}
.footer .heading-txt:hover {
  color: var(--black);
}
.footer .heading-sec {
  display: flex;
  align-items: center;
  gap: 25px;
  justify-content: center;
  flex-wrap: wrap;
}
.footer .footer-logo {
  width: 250px;
}

.footer .footer-hr {
  background-color: #99decf;
}

.footer .post-footer {
  color: #6e9e94;
  font-weight: 500;
}

.footer .meet_doctor {
  cursor: pointer;
  display: inline-block;
  height: 40px;
  width: 400px;
  background: linear-gradient(to right, #c1fef1, #50d9ba);
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 25px 3px 25px 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
}

/* lannding page */

.landing_banner {
  background-color: #e5f8f4;
  padding: 80px 0px 150px;
}

.landing_banner .container-fluid {
  padding-left: 70px;
}

.cmnLeaf_headerTxt {
  color: #95563c;
  font-size: 50px;
  font-weight: 300;
  line-height: 66px;
  position: relative;
  z-index: 1;
}
.cmnLeaf_headerTxt::before {
  content: "";
  background: url("./Assets/Images/landing/leaf.png") no-repeat;
  background-size: 100% 100%;
  width: 91px;
  height: 85px;
  display: inline-block;
  position: absolute;
  top: -28px;
  left: -49px;
  z-index: -1;
}

.cmn_land_subTxt {
  color: #033e30;
  font-size: 51px;
  font-weight: 600;
  line-height: 55px;
}
.cmn_land_subTxt .bold-txt {
  font-weight: 700;
}

.cmn_land_des {
  color: #3c7c6d;
  font-weight: 400;
  line-height: 22px;
}

/* .landing_banner .request-btn {
  border-radius: 3px !important;
  font-weight: 500 !important;
  padding: 7px 30px;
} */
.cmn_land_btn {
  border-radius: 3px !important;
  font-weight: 500 !important;
  padding: 7px 30px !important;
}
.landing_banner .ryt-sec {
  position: relative;
}
.landing_banner .ryt-sec .bigLeaf_img {
  height: 400px;
  z-index: 2;
  position: relative;
}
.landing_banner .ryt-sec .StrokeTxt_img {
  position: absolute;
  top: -49px;
  right: -37px;
  width: 161px;
  z-index: 1;
}
.landing_banner .left-sec {
  padding-top: 50px;
}

/* who we are */

.who_we_are {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #edfffb;
}
.who_we_are .left-sec {
  position: relative;
}
.who_we_are .left-sec:after {
  content: "";
  background: url("./Assets/Images/landing/Flying-green-leaves.png") no-repeat;
  width: 100px;
  height: 91px;
  background-size: 100% 100%;
  display: inline-block;
  position: absolute;
  bottom: -44px;
  right: -54px;
}

.read-more-btn .arrow_icon {
  font-size: 20px;
}

.how_we_work {
  padding-top: 70px;
  padding-bottom: 70px;
}

.how_we_work .heading-txt {
  display: inline;
}

.how_we_work .des {
  color: #7c4b3c;
}

.how_we_work .services_list {
  margin-top: 60px;
}

.how_we_work .services_list .sec-1 {
  background-color: #fff0de;
  /* padding-bottom: 30px; */
  position: relative;
}

.services_list .sec-1 .title-txt,
.services_list .sec-2 .title-txt {
  color: #563a2b;
  font-size: 27px;
  font-weight: 500;
}
.services_list .sec-1 .des,
.services_list .sec-2 .des {
  color: #675249;
  font-size: 14px;
  font-weight: 400;
}

.services_list .sec-1 .women-img {
  margin-top: -52px;
  height: 350px;
}

.how_we_work .services_list .sec-2 {
  background-color: #fff0de;
  /* padding-top: 30px; */
  position: relative;
}
.services_list .sec-2 .child-img {
  margin-left: -38px;
  max-width: 455px;
  width: 455px;
  margin-top: 46px;
}

.how_we_work .services_list .sec-3 {
  background-color: #fdecee;
  padding-bottom: 30px;
  position: relative;
}

.services_list .sec-3 .title-txt {
  color: #5b3131;
  font-size: 27px;
  font-weight: 500;
}
.services_list .sec-3 .des {
  color: #4b3c36;
  font-size: 14px;
  font-weight: 400;
}

.services_list .sec-3 .surgical-img {
  margin-top: -35px;
}

.services_list .sec-1,
.services_list .sec-2,
.services_list .sec-3 {
  max-height: 460px;
  min-height: 460px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.services_list .sec-1 .details,
.services_list .sec-2 .details,
.services_list .sec-3 .details {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.services_list .row {
  align-items: center;
  justify-content: space-between;
}
.services_list .row .col1,
.services_list .row .col2,
.services_list .row .col3 {
  max-width: 490px;
}
.how_we_work .flying_leaf {
  position: relative;
}
.how_we_work .flying_leaf::before {
  content: "";
  background: url("./Assets/Images/landing/Flying-green-leaves.png") no-repeat;
  background-size: 100% 100%;
  width: 140px;
  height: 128px;
  display: inline-block;
  position: absolute;
  left: 0px;
  top: 0px;
  left: -64px;
  top: -47px;
}

.watch_story {
  padding-top: 40px;
  padding-bottom: 80px;
  background-color: #edfffb;
}

.watch_story .left-sec {
  padding-top: 30px;
}

.watch_story .flying-leaf {
  position: relative;
}

.watch_story .flying-leaf:after {
  content: "";
  width: 132px;
  height: 124px;
  display: inline-block;
  background: url("./Assets/Images/landing/Flying-green-leaves.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  bottom: -87px;
  right: -55px;
}

.watch_story .ryt_arrow {
  height: 29px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  cursor: pointer;
}
.watch_story .ryt_arrow:hover {
  filter: brightness(0.3);
}

.how_we_work_new {
  padding-top: 70px;
  padding-bottom: 70px;
}

.how_we_work_new .des {
  color: #7c4b3c;
}

.how_we_work_new .service_lists {
  margin-top: 40px;
}
.how_we_work_new .cmn-sec {
  display: flex;
  gap: 20px;
  align-items: end;
}

.how_we_work_new .service_leaf {
  height: 150px;
}

.how_we_work_new .service_lists .title-txt {
  color: #033e30;
  font-weight: 500;
  font-size: 26px;
}

.how_we_work_new .service_lists .des {
  color: #77635d;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
}

.land_contact_us {
  padding-top: 100px;
  padding-bottom: 50px;
  background-color: #edfffb;
}

.land_contact_us .ins_sec {
  background: linear-gradient(to right, #c1fef1, #50d9ba);
  padding: 80px 50px 50px 70px;
  border-radius: 15px;
}

.land_contact_us .cmn_land_subTxt {
  font-size: 42px;
}

.land_contact_us .contact_us_img {
  max-width: 560px;
  margin-top: -150px;
}

.land_contact_us .cmnLeaf_headerTxt {
  font-size: 46px;
  line-height: 45px;
}

.land_contact_us .cmnLeaf_headerTxt::before {
  top: -42px;
  left: -60px;
}

.how_we_work.new .doctor-img {
  min-height: 320px;
  width: 100%;
  border-radius: 10px;
}

.how_we_work.new .title-txt {
  color: var(--black);
  font-size: 25px;
  font-weight: 600;
}
.how_we_work.new .des {
  color: var(--black);
  font-size: 16px;
  font-weight: 500;
}

.how_we_work.new .details {
  padding: 15px;
}

/* signup */
.cmn-signup .container {
  /* max-width: 100%; */
}
.cmn-signup .cmn_input_sec {
  background-color: #edfffb;
  padding: 30px 70px;
  height: 100%;
}
.cmn_input_sec .logo {
  width: 130px;
  margin-right: auto;
  margin-left: auto;
}
.cmn_input_sec .heading_txt {
  color: #2e384d;
  font-weight: 700;
  font-size: 30px;
}

.cmn_input_sec .input_details {
  /* margin-top: 30px; */
}

.cmn_input_label {
  color: #444444;
  font-weight: normal;
  font-size: 16px;
}
.cmn_input_field {
  background-color: #e5f8f4 !important;
  border-radius: 5px;
  height: 43px;
  width: 100%;
  color: black;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  font-size: 15px;
}
.cmn_input_field::placeholder {
  color: #444444;
  font-size: 15px;
}
.cmn_inp_btn {
}
.cmn_inp_btn button {
  border-radius: 5px;
  font-weight: 700 !important;
  font-size: 15px !important;
  width: 100%;
  height: 45px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.already_have {
  color: #2e384d;
}
.already_have a {
  color: #2e384d;
}
.already_have a:hover {
  /* color: var(--themeclr); */
  color: var(--black);
}

.cmn_inp_scroll {
  height: 400px;
  overflow-y: auto;
}

.cmn_inp_scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.cmn_inp_scroll::-webkit-scrollbar {
  width: 3px;
}

.cmn_inp_scroll::-webkit-scrollbar-thumb {
  background-color: var(--themeclr);
}

.cmn-signup .container {
  padding: 0px;
  margin: 0px;
}
.cmn-signup .row {
  padding: 0px;
  margin: 0px;
}
.cmn-signup .row .col {
  padding: 0px;
  margin: 0px;
}

.cmn_left_img {
  /* width: 100%;
  height: 100%;
  min-height: 100vh;
  max-height: 100vh; */
  height: 100%;
  background: linear-gradient(180deg, #033e3000, #033e30b3);
}
.cmn-signup .sign_up_img {
  /* width: 100%;
  height: 100%; */
  width: 100%;
  height: 100%;
  min-height: 100vh;
  /* max-height: 100vh; */
  z-index: -1;
  position: relative;
}

/* otp verification */

.cmn-signup .cmn_input_sec {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
}
.cmn-signup .cmn_input_sec div {
  /* width: 100%; */
}

/* Dashboard */

.dashboard_sec .left_side_sec {
  width: 260px;
  padding: 20px 50px 20px 0px;
  background-color: #e5f8f4;
  /* height: 100%; */
  min-height: 100vh;
}

.dashboard_sec .left_side_sec .sidebar_sec {
  position: fixed;
  width: 260px;
}
.sidebar_sec .logo_sec .logo {
  max-width: 200px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

.sidebar_sec .DashSide_customDrop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar_sec .DashSide_customDrop .arrow-btn {
  background-color: transparent !important;
  border: none;
  outline: none;
  box-shadow: none !important;
  margin-right: 3px;
}

.sidebar_sec .DashSide_customDrop.active .arrow-btn svg {
  color: var(--white);
}

.DashSide_customDrop .arrow-btn svg {
  color: #202020;
}

.sideheader .cmn-hover:hover svg {
  color: var(--white);
}

.sideheader .sub-headings {
  border-radius: 10px;
  padding: 15px 25px 10px 15px;
  background-color: #d8ede9;
  position: relative;
  left: 35px;
  display: inline-block;
}
.sideheader .sub-headings a {
  color: #202020;
  font-size: 13px;
  display: block;
  text-decoration: none;
}
.sideheader .sub-headings a.active {
  color: #033e30;
  font-weight: 600;
}
.sideheader .sub-headings a:hover {
  font-weight: 600;
}

.dashboard_sec .sideheader {
  margin-top: 30px;
  width: 210px;
}

.sideheader .cmn-margin {
  margin-bottom: 15px;
}

.sideheader .cmn-hover.active {
  background-color: #033e30;
  border-radius: 0px 13px 13px 0px;
}
.sideheader .navi-link {
  display: flex;
  /* align-items: center; */
  padding-left: 13px;
  /* gap: 8px; */
  text-decoration: none;
  width: 100%;
  height: 40px;
  color: var(--white);
  font-weight: 500;
}
.sideheader .cmn-hover:hover {
  background-color: #033e30;
  border-radius: 0px 13px 13px 0px;
}

.sidebar_sec .sidebar_logo {
  height: 24px;
  margin-right: 15px;
}
.sideheader .cmn-hover.active .sidebar_logo {
  filter: brightness(0) invert(1);
}

.sideheader .cmn-hover:hover .sidebar_logo {
  filter: brightness(0) invert(1);
}

.sideheader .title-txt {
  display: flex;
  align-items: center;
}
.sideheader .cmn-hover.inactive .title-txt {
  color: #202020;
}

.sideheader .cmn-hover.active .title-txt {
  color: var(--white);
}

.sideheader .cmn-hover:hover .title-txt {
  color: var(--white);
}

.sideheader .logo_sec a {
  width: 100%;
}

.dashboard_sec .right_side_sec {
  width: calc(100% - 260px);
  height: 100%;
  min-height: 100vh;
  padding: 17px;
}

.dashboard_header .navbar {
  padding: 0px 0px;
}
.dashboard_header .navbar-collapse {
  /* display: block !important; */
}

.dashboard_sec .right_side_sec .dashboard_header {
  background-color: #f7f7f7;
  border-radius: 15px;
  padding: 5px 5px;
}

.dashboard_header .profile-drop button {
  background-color: transparent !important;
  border: none !important;
  outline: none;
  box-shadow: none;
}
.dashboard_header .profile-drop .dropdown-menu .dropdown-item:active {
  background-color: #f8f9fa !important;
  color: #212529 !important;
}

.dashboard_header .profile-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.dashboard_header .profile_info {
  display: flex;
  gap: 10px;
  color: #033e30 !important;
}
.dashboard_header .profile_info::after {
  display: none;
}

.dash-header-search {
  position: relative;
}
.dash-header-search input {
  border: 1px solid #c5c5c5;
  border-radius: 7px;
  outline: none;
  height: 35px;
  padding-left: 37px;
  color: #343434;
  font-weight: 500;
  width: 500px;
  font-size: 14px;
}
.dash-header-search .search-logo {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 12px;
  top: 9px;
}

.dashboard_header .navbar-toggler {
  color: var(--themeclr);
  box-shadow: none !important;
}

.dashboard_header .mbl-logo img {
  height: 50px;
}

.headerNav-offcanvas {
  width: 280px !important;
  background-color: #e5f8f4;
}

.close_offcanvas_logo {
  width: 17px;
  height: 17px;
  margin-right: 10px;
}

.headerNav-offcanvas .offcanvas-body {
  padding-left: 0px;
}
.headerNav-offcanvas .sidebar_sec .logo_sec .logo {
  margin-left: 20px;
}

/* dash content */

.dash_home .heading_txt {
  color: #202020;
  font-weight: 600;
}
.dash_home .back-btn {
  padding: 6px 20px;
  border-radius: 5px !important;
  font-size: 14px !important;
}

.dash_home .choose-required {
  padding-top: 40px;
  padding-bottom: 40px;
}

.choose-required .require_card {
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px #00000029;
  border: 1px solid #707070;
  border-radius: 10px;
  width: 140px;
  padding: 25px 20px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.choose-required .require_card.active {
  background-color: #e5f8f4;
}

.choose-required .require_card .checked {
  position: absolute;
  background-color: #202020;
  border-radius: 50%;
  width: 25px;
  color: white;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -12px;
  right: -12px;
}
.choose-required .title-txt {
  font-weight: 500;
  color: #202020;
  font-size: 20px;
}

.choose-required .require_card .cmn-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.choose-required .require_card .type {
  color: #141414;
  font-weight: 600;
}

.choose-required .require_sec {
  display: flex;
  gap: 40px;
  align-items: center;
}
.choose-required .require_sec .or-txt {
  color: #343434;
  font-size: 18px;
  font-weight: 600;
}

.cmnDash_heading_txt {
  color: #202020;
  font-weight: 600;
}

.cmn_smlCard {
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px #00000029;
  border: 1px solid #707070;
  border-radius: 10px;
  width: 130px;
  padding: 15px;
  min-height: 140px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.cmn_smlCard.active {
  background-color: #e5f8f4;
}

.cmn_smlCard .cmn-img {
  width: 43px;
  height: 43px;
  border-radius: 50%;
}
.cmn_smlCard .type {
  color: #141414;
  font-weight: 600;
  font-size: 13px;
}

.cmn_smlCard .checked {
  position: absolute;
  background-color: #202020;
  border-radius: 50%;
  width: 25px;
  color: white;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -12px;
  right: -12px;
}

.select-type {
  display: flex;
  gap: 24px;
  align-items: center;
  flex-wrap: wrap;
}

.select-type .or-txt {
  color: #343434;
  font-size: 18px;
  font-weight: 600;
}

.cmn_dash_header_sec .title-txt {
  font-weight: 500;
  color: #202020;
  font-size: 20px;
}

.cmn_dash_header_sec .back-btn {
  padding: 6px 20px;
  border-radius: 5px !important;
  font-size: 14px !important;
}

.choose-doctors .doc_CusCard {
  cursor: pointer;
}

/* new appointment booking */

.cmn_dash_header_sec .next-btn {
  padding: 6px 20px;
  border-radius: 5px !important;
  font-size: 14px !important;
}

.new_appointment_booking .ryt-sec {
  border: 1px solid #e7e7e7;
  padding: 20px;
  border-radius: 10px;
}

.appoinment_booking_modal.modalbox .btn {
  padding: 7px 28px;
  font-size: 15px;
  line-height: 21px;
  border-radius: 5px;
}

.appoinment_booking_modal.modalbox .booking {
  font-weight: 700;
}

/* custom calender start */
.appointment_customCalendar {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 6px #00000029;
  width: 100%;
  margin-bottom: 0;
  padding: 9px 9px 9px;
  margin-top: 4px;
}

.appointment_customCalendar .react-calendar {
  /* border: 1px solid #eeeeee; */
  border: none;
  border-radius: 5px;
  width: 100%;
  /* padding: 20px 14px 25px; */
  height: 100%;
}

.appointment_customCalendar .react-calendar__navigation {
  justify-content: center;
  margin-bottom: 10px;
}

.appointment_customCalendar .react-calendar button {
  color: #1d2634;
  font-weight: 600;
}

.appointment_customCalendar .react-calendar__navigation__next2-button,
.appointment_customCalendar .react-calendar__navigation__prev2-button {
  display: none;
}

.appointment_customCalendar .react-calendar__navigation__next-button,
.appointment_customCalendar .react-calendar__navigation__prev-button {
  font-size: 20px;
}

.appointment_customCalendar .react-calendar__navigation__label {
  flex-grow: inherit !important;
  padding: 0px 20px;
}

.appointment_customCalendar abbr[title] {
  text-decoration: none;
  color: #bebebe;
  text-transform: capitalize;
  font-size: 14px;
}

.appointment_customCalendar .react-calendar__month-view__days button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 35px;
  height: 55px; */
  display: grid;
  place-content: center;
}
.appointment_customCalendar
  .react-calendar
  button.react-calendar__month-view__days__day--neighboringMonth {
  color: #bebebe;
}

.appointment_customCalendar
  .react-calendar
  button.react-calendar__tile--now
  abbr,
.appointment_customCalendar .react-calendar__tile--active:enabled:hover abbr,
.appointment_customCalendar .react-calendar__tile--active:enabled:focus abbr,
.appointment_customCalendar .react-calendar__tile--active abbr {
  background-color: #033e30;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
}

.appointment_customCalendar .react-calendar__tile--now {
  background: transparent !important;
}
/* custom calender end */

/* custom time display start */

.custom_TimeDisplay .heading_txt {
  color: #1d2634;
  font-weight: 500;
}

.custom_TimeDisplay .btn-green.active {
  background-color: #033e30 !important;
  color: #fff !important;
  font-size: 13px;
  border-radius: 4px !important;
  padding: 6px 20px !important;
}

.custom_TimeDisplay .btn-green {
  background-color: #f7f7f7 !important;
  color: #000000 !important;
  font-size: 13px;
  border-radius: 4px !important;
  padding: 6px 20px !important;
  border: 1px solid #033e30;
}
.custom_TimeDisplay .btn-green:hover {
  background-color: #033e30 !important;
  color: #fff !important;
}

.custom_TimeDisplay .gridbox {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  overflow-x: auto;
}

/* custom time display end */

.patientForm .form-label {
  color: #343434;
  font-weight: 400;
  font-size: 15px;
}
.patientForm textarea {
  border-color: #dcdcdc !important;
  height: 100px;
  box-shadow: none !important;
}

.doctors_list .customCard {
  padding: 18px 20px;
  margin-bottom: 0;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 6px #00000029;
}

.doctors_list .cus_flexbox {
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 12px;
}

.doctors_list .doctorimg {
  width: 100%;
  max-width: 60px;
  min-width: 60px;
  display: block;
  margin: auto;

  position: relative;
}
.doctors_list .doctoricon {
  max-width: 30px;
  min-width: 30px;
  width: 100%;
  /* margin-top: -25px;
  margin-left: 15px; */

  position: absolute;
  bottom: -10px;
  left: 15px;
}

.doctors_list .doctorname {
  color: #111111;
  margin-bottom: 7px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
}

.doctors_list .doctorsubname {
  display: block;
  color: #8e97a5;
  font-size: 15px;
}

.doctors_list .btn-details {
  font-size: 11px;
  padding: 7px 18px;
  border-radius: 4px;
}

.doctors_list .btn-grp {
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.doctorProfiledetails .bannerImg {
  width: 100%;
  height: 90px;
}

.doctorProfiledetails .profileDetail {
  padding: 10px;
}

.doctorProfiledetails .doctorimg {
  height: 70px;
  margin-top: -40px;
  margin-left: 20px;
}

.doctorProfiledetails .doctorname {
  color: #111111;
  margin-bottom: 7px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
}

.doctorProfiledetails .doctorsubname {
  display: block;
  color: #8e97a5;
  font-size: 15px;
}
.doctorProfiledetails .content {
  color: #202020;
  font-size: 13px;
  margin-bottom: 0;
  line-height: 20px;
}

.doctorProfiledetails .treatments {
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
}
.doctorProfiledetails .treatmenthead {
  color: #202020;
  display: block;
  font-size: 16px;
  font-weight: 500;
}

.doctorProfiledetails .treatmentslist span {
  color: #033e30;
  font-size: 13px;
  padding-left: 8px;
}

.doctorProfiledetails .checked-img {
  height: 18px;
}
.doctorProfiledetails .treatmentslist li {
  list-style-type: none;
}
.doctorProfiledetails .treatmentslist {
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.profileDetail .btmContent label {
  color: #033e30;
  font-size: 15px;
  text-decoration: underline;
  padding-left: 10px;
}

.profileDetail .btmContent .form-check-input:checked {
  background-color: #033e30;
  border-color: #033e30;
}

.profileDetail .btmContent .form-check {
  display: flex;
  align-items: end;
  margin: 28px 0px;
}

.profileDetail .btmContent .form-check .form-check-input {
  width: 20px;
  height: 20px;
  box-shadow: none;
}

.doctorsdetail .bottomcontent .form-check .form-check-input:focus {
  box-shadow: none;
}

.profileDetail .paynow-btn {
  padding: 6px 20px;
  border-radius: 5px !important;
  font-size: 14px !important;
}

.profileDetail .paylater-btn {
  padding: 6px 20px;
  border-radius: 5px !important;
  font-size: 14px !important;
}

.booking_completed {
  background-color: #f7f7f7;
  border-radius: 7px;
  padding: 15px;
}

.booking_completed .cmn-sec .heading_txt {
  color: #202020;
  font-weight: 500;
  font-size: 16px;
}
.booking_completed .cmn-sec .sub_heading_txt {
  color: #202020;
  font-weight: 400;
  font-size: 11px;
}
.booking_completed .cmn-sec {
  display: flex;
  gap: 10px;
  position: relative;
  margin-top: 15px;
}
.booking_completed .cmn-sec:first-child {
  margin-top: 0px;
}
.booking_completed .cmn-sec:last-child::after {
  display: none;
}

.booking_completed .cmn-sec .lft_sec {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  border: 1px solid #033c2e;
  background-color: #e5f8f4;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 2px;
}
.booking_completed .cmn-sec.active .lft_sec {
  background-color: #033e30;
}
.booking_completed .cmn-sec .lft_sec .center-dot {
  width: 10px;
  height: 10px;
  background-color: #e5f8f4;
  border-radius: 50%;
  display: inline-block;
}
.booking_completed .cmn-sec.active:after {
  content: "";
  height: 80px;
  border: 1px dashed #033e30;
  position: absolute;
  top: 18px;
  left: 9px;
}

.raise_grievance .header_sec .des_txt {
  color: #202020;
  font-size: 14px;
}
.raise_grievance .content-sec .des {
  color: #202020;
  font-size: 14px;
}

.create_ticket .form-label {
  color: #343434;
  font-weight: 400;
  font-size: 15px;
}

.create_ticket textarea {
  background-color: #f7f7f7 !important;
  height: 100px;
  border-radius: 5px;
  box-shadow: none !important;
  border: none;
  font-size: 14px;
}

.upload_file_attachment {
  position: relative;
  overflow: hidden;
  display: block;
  background-color: #f7f7f7 !important;
  padding: 20px;
  border-radius: 5px;
}

.upload_file_attachment .btn {
  color: #7e7e89;
  background-color: transparent;
  font-size: 14px;
  padding: 0px;
  width: 100%;
  text-align: center;
}

.upload_file_attachment .btn .browse_txt {
  color: #033e30;
}

.upload_file_attachment input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.custom_Attach_Formcontrol {
  /* background-color: #f7f7f7 !important;
  padding: 20px;
  border-radius: 5px; */
}

.raise_grievance .cancelBtn {
  font-size: 14px;
  font-weight: 600;
  padding: 7px 20px;
  border-radius: 5px;
  border: 1px solid #ede6e6 !important;
  background-color: #f7f7f7 !important;
  color: var(--black) !important;
}

.raise_grievance .cancelBtn:hover {
  color: #033e30 !important;
  background-color: #eaeef0 !important;
}

.raise_grievance .submitBtn {
  font-size: 14px;
  font-weight: 600;
  padding: 7px 20px;
  border-radius: 5px;
}

/* rate experience */
.rate_experience .cmn-steps .count {
  background-color: #e5f8f4;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #033e30;
  color: #033e30;
  font-weight: 500;
}

.cmn-steps .details .heading_txt {
  color: #033e30;
  font-weight: 600;
  font-size: 18px;
}
.cmn-steps .details .des {
  color: #202020;
  font-size: 14px;
}

.rate_experience .cmn-steps {
  display: flex;
  align-items: start;
  gap: 20px;
}
.cmn-steps .details .all-ratings svg {
  font-size: 20px;
}
.cmn-steps .details .all-ratings {
  display: flex;
  align-items: center;
  gap: 8px;
}
.cmn-steps .details .all-ratings .star-fill {
  color: #f8b84e;
}

.cmn-steps .details .all-ratings .star-unfill {
  color: #e8e8e8;
}

.rate_experience textarea {
  background-color: #f7f7f7 !important;
  height: 100px;
  border-radius: 5px;
  box-shadow: none !important;
  border: none;
  font-size: 14px;
}

.rate_experience .ftr_BtnSec {
  margin-left: 60px;
}

.rate_experience .header_sec .des_txt {
  color: #202020;
  font-size: 14px;
}

/* refer friend */
.refer_friend .header_sec .des_txt {
  color: #202020;
  font-size: 14px;
}

.refer_friend .heading_txt {
  color: #202020;
  font-weight: 600;
}

.refer_friend .share_friends {
  background: url("./Assets/Images/dashboard/share_frndBg.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 250px;
  border-radius: 15px;
}

.share_friends .share_details .title_txt {
  font-size: 25px;
  font-family: Oswald, Regular;
  color: var(--white);
  font-weight: 500;
}
.refer_friend .share_details .refer_code {
  background-color: #f8f8fc;
  border-radius: 8px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.refer_friend .share_details .address {
  font-size: 14px;
  font-weight: 500;
}
.refer_friend .share_details .copy-icon svg {
  font-size: 17px;
  font-weight: 500;
  color: var(--black);
  cursor: pointer;
}

.refer_friend .share_details .social_icons {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;
}
.refer_friend .share_details .social_icons .icons {
  width: 34px;
  height: 34px;
  border-radius: 5px;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
}
.refer_friend .share_details .social_icons a:hover .icons {
  color: #202020;
}

.refer_friend .share_details {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  gap: 20px;
  padding: 15px;
}

.refer_friend .share_friends {
  margin-top: 40px;
  margin-bottom: 40px;
}

.availability_page .add-careBtn {
  font-size: 13px !important;
}
.cancel-careBtn {
  font-size: 13px !important;
}

.reschedule_Appointment .pre_header {
  background-color: #4caf50;
  color: var(--white);
  font-size: 17px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 6px;
}

.reschedule_Appointment .custom_TimeDisplay .btn-green {
  font-size: 8px;
  padding: 7px 12px !important;
  font-weight: 500;
}
.reschedule_Appointment .cmn_dash_header_sec .next-btn {
  padding: 5px 23px;
  border-radius: 4px !important;
  font-size: 13px !important;
}
.cmn_dash_header_sec .back-btn {
  padding: 5px 23px;
  border-radius: 4px !important;
  font-size: 13px !important;
}
.reschedule_Appointment .appointment_customCalendar {
  height: 99%;
}

.reschedule_Appointment .cmn_dash_header_sec .title-txt {
  font-size: 17px;
}

.patient_waiting .header_txt {
  font-weight: 600 !important;
  line-height: 30px;
}

.patient_waiting .home-btn {
  padding: 9px 30px !important;
  border-radius: 4px !important;
}

.modalbox .cancelimg svg {
  width: 100%;
  max-width: 150px;
}

/* media query */
@media (max-width: 380px) {
  .watch_story .ryt_arrow {
    height: 20px;
  }
  .cmn_dash_header_sec .mbl {
    flex-direction: column;
    align-items: start !important;
    gap: 15px;
  }
  .doctors_list .doctorimg {
    max-width: 51px;
    min-width: 50px;
  }
  .doctors_list .doctoricon {
    max-width: 25px;
    min-width: 25px;
    left: 13px;
  }
  .booking_completed .cmn-sec .sub_heading_txt {
    font-size: 11px;
  }
  .select-type .cmn_smlCard {
    width: 115px;
    min-height: 125px;
    padding: 10px;
  }
  .choose-required .require_card {
    width: 110px;
    min-height: 120px;
    padding: 10px;
  }
}
@media (min-width: 381px) and (max-width: 479px) {
  .watch_story .ryt_arrow {
    height: 23px;
  }
  .booking_completed .cmn-sec .sub_heading_txt {
    font-size: 12px;
  }
  .select-type .cmn_smlCard {
    width: 120px;
    min-height: 130px;
    padding: 10px;
  }
  .choose-required .require_card {
    width: 120px;
    min-height: 130px;
    padding: 10px;
  }
}
@media (max-width: 479px) {
  .footer .heading-sec {
    gap: 15px;
  }
  .footer .post-footer {
    font-size: 14px;
  }

  .landing_banner {
    padding: 0px 0px 50px;
  }
  .landing_banner .container-fluid {
    padding-left: 1.5rem;
  }
  .cmnLeaf_headerTxt {
    font-size: 35px;
  }
  .cmn_land_subTxt {
    font-size: 32px !important;
    line-height: 36px !important;
  }
  .landing_banner .des {
    line-height: 19px;
    font-size: 15px;
  }
  .cmn_land_btn {
    font-size: 15px;
  }
  .cmnLeaf_headerTxt::before {
    width: 69px;
    height: 60px;
    top: -7px;
    left: -37px;
  }

  .landing_banner .ryt-sec .StrokeTxt_img {
    top: -35px;
    right: 10px;
    width: 69px;
  }
  .landing_banner .ryt-sec .bigLeaf_img {
    height: unset;
  }
  .land_custom_container {
    padding-left: 1.5rem !important;
  }
  .who_we_are .left-sec:after {
    bottom: -50px;
    right: 0px;
  }

  .services_list .sec-1 .women-img {
    height: unset;
    margin-top: 20px;
  }
  .services_list .sec-1,
  .services_list .sec-2,
  .services_list .sec-3 {
    min-height: unset;
    max-height: unset;
  }
  .services_list .sec-2 .child-img {
    margin-left: unset;
    max-width: unset;
    width: unset;
    margin-top: unset;
  }
  .services_list .sec-3 .surgical-img {
    margin-top: 20px;
  }
  .how_we_work .services_list .sec-3 {
    padding-bottom: unset;
  }
  .services_list .row .col1,
  .services_list .row .col2,
  .services_list .row .col3 {
    max-width: unset;
  }
  .watch_story .flying-leaf:after {
    width: 110px;
    height: 100px;
    bottom: -40px;
    right: 5px;
  }
  .how_we_work_new .service_leaf {
    height: 100px;
  }
  .how_we_work_new .cmn-sec {
    gap: 13px;
    align-items: unset;
  }
  .how_we_work_new {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .land_contact_us .contact_us_img {
    max-width: 100%;
    margin-top: unset;
  }
  .land_contact_us {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .land_contact_us .ins_sec {
    padding: 40px 20px 40px 20px;
  }
  .land_contact_us .cmnLeaf_headerTxt {
    font-size: 35px;
  }
  .land_contact_us .cmn_land_subTxt {
    font-size: 35px;
  }
  .land_contact_us .cmnLeaf_headerTxt::before {
    top: -26px;
    left: -43px;
  }
  .footer .meet_doctor {
    width: 300px;
    height: 40px;
  }
  .how_we_work.new .doctors_list .row .col:last-child {
    margin-bottom: 0px !important;
  }
  .how_we_work.new {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .cmn-signup .sign_up_img {
    width: unset;
    height: unset;
    min-height: unset;
    max-height: unset;
  }
  .cmn-signup .cmn_input_sec {
    padding: 30px 20px;
  }
  .choose-required .require_card .cmn-img {
    width: 40px;
    height: 40px;
  }
  .choose-required .require_sec {
    gap: 20px;
  }
  .choose-required .require_card {
    /* padding: 15px 15px; */
  }
  .choose-required .require_card .type {
    font-size: 14px;
  }
  .cmn_smlCard .cmn-img {
    width: 40px;
    height: 40px;
  }
  .cmn_smlCard .type {
    font-size: 14px;
  }
  .select-type {
    gap: 20px;
  }
  .share_friends .share_details .title_txt {
    font-size: 23px;
  }
  .refer_friend .share_friends {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .profileDetail .btmContent .form-check {
    align-items: center;
  }
  .appoinment_booking_modal.modalbox .successfulimg svg {
    max-width: 150px;
  }
}
@media (min-width: 480px) and (max-width: 575px) {
  .footer .heading-sec {
    gap: 20px;
  }
  .footer .post-footer {
    font-size: 14px;
  }
  .landing_banner .ryt-sec .StrokeTxt_img {
    top: -35px;
    right: 10px;
    width: 69px;
  }
  .landing_banner .ryt-sec .bigLeaf_img {
    height: 300px;
  }
  .landing_banner .ryt-sec .StrokeTxt_img {
    top: -5px;
    right: 0px;
    width: 95px;
  }
  .landing_banner {
    padding: 0px 0px 50px;
  }
  .landing_banner .container-fluid {
    padding-left: 1.5rem;
  }
  .cmnLeaf_headerTxt {
    font-size: 35px;
  }
  .cmn_land_subTxt {
    font-size: 32px;
    line-height: 36px;
  }
  .cmn_land_des {
    line-height: 19px;
    font-size: 15px;
  }
  .cmn_land_btn {
    font-size: 15px;
  }
  .cmnLeaf_headerTxt::before {
    width: 69px;
    height: 60px;
    top: -7px;
    left: -37px;
  }
  .land_custom_container {
    padding-left: 1.5rem !important;
  }
  .who_we_are .left-sec:after {
    bottom: -50px;
    right: 0px;
    width: 100px;
  }

  .services_list .sec-1 .women-img {
    height: unset;
    margin-top: 20px;
  }
  .services_list .sec-1,
  .services_list .sec-2,
  .services_list .sec-3 {
    min-height: unset;
    max-height: unset;
  }
  .services_list .sec-2 .child-img {
    margin-left: unset;
    max-width: unset;
    width: unset;
    margin-top: unset;
  }
  .services_list .sec-3 .surgical-img {
    margin-top: 20px;
  }
  .how_we_work .services_list .sec-3 {
    padding-bottom: unset;
  }
  .services_list .row .col1,
  .services_list .row .col2,
  .services_list .row .col3 {
    max-width: unset;
  }
  .how_we_work .flying_leaf::before {
    display: none;
  }
  .watch_story .flying-leaf:after {
    width: 110px;
    height: 100px;
    bottom: -40px;
    right: 5px;
  }
  .watch_story .ryt_arrow {
    height: 25px;
  }
  .how_we_work_new .service_leaf {
    height: 130px;
  }
  .how_we_work_new .cmn-sec {
    gap: 13px;
    align-items: end;
  }
  .how_we_work_new {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .land_contact_us .contact_us_img {
    max-width: 100%;
    margin-top: unset;
  }
  .land_contact_us {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .land_contact_us .ins_sec {
    padding: 40px 20px 40px 20px;
  }
  .land_contact_us .cmnLeaf_headerTxt {
    font-size: 35px;
  }
  .land_contact_us .cmn_land_subTxt {
    font-size: 35px;
  }
  .land_contact_us .cmnLeaf_headerTxt::before {
    top: -26px;
    left: -43px;
  }
  .footer .meet_doctor {
    width: 300px;
    height: 40px;
  }
  .how_we_work.new .doctors_list .row .col:last-child {
    margin-bottom: 0px !important;
  }
  .how_we_work.new {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .cmn-signup .sign_up_img {
    width: unset;
    height: unset;
    min-height: unset;
    max-height: unset;
  }
  .cmn-signup .cmn_input_sec {
    padding: 30px 20px;
  }
  .booking_completed .cmn-sec .sub_heading_txt {
    font-size: 14px;
  }
  .refer_friend .share_friends {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .profileDetail .btmContent .form-check {
    align-items: center;
  }
  .appoinment_booking_modal.modalbox .successfulimg svg {
    max-width: 150px;
  }
  .select-type .cmn_smlCard {
    width: 120px;
    min-height: 130px;
    padding: 10px;
  }
  .choose-required .require_card {
    width: 120px;
    min-height: 130px;
    padding: 10px;
  }
  .choose-required .require_sec {
    gap: 20px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .footer .heading-sec {
    gap: 15px;
  }
  .landing_banner .ryt-sec .bigLeaf_img {
    height: 300px;
  }
  .landing_banner .ryt-sec .StrokeTxt_img {
    top: -5px;
    right: 0px;
    width: 95px;
  }

  .landing_banner {
    padding: 0px 0px 80px;
  }
  .landing_banner .container-fluid {
    padding-left: 35px;
  }
  .cmnLeaf_headerTxt {
    /* font-size: 35px; */
  }
  .land_custom_container {
    padding-left: 1.5rem !important;
  }
  .who_we_are .left-sec:after {
    bottom: -50px;
    right: 0px;
    width: 100px;
  }

  .services_list .sec-1 .women-img {
    height: unset;
    margin-top: 20px;
  }
  .services_list .sec-1,
  .services_list .sec-2,
  .services_list .sec-3 {
    min-height: unset;
    max-height: unset;
  }
  .services_list .sec-2 .child-img {
    margin-left: unset;
    max-width: unset;
    width: unset;
    margin-top: unset;
  }
  .services_list .sec-3 .surgical-img {
    margin-top: 20px;
  }
  .how_we_work .services_list .sec-3 {
    padding-bottom: unset;
  }
  .services_list .row .col1,
  .services_list .row .col2,
  .services_list .row .col3 {
    max-width: unset;
  }
  .watch_story .flying-leaf:after {
    width: 110px;
    height: 100px;
    bottom: -40px;
    right: 5px;
  }
  .watch_story .ryt_arrow {
    height: 25px;
  }

  .how_we_work_new .service_leaf {
    height: 130px;
  }
  .how_we_work_new .cmn-sec {
    gap: 13px;
    align-items: end;
  }
  .how_we_work_new {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .land_contact_us .contact_us_img {
    max-width: 100%;
    margin-top: unset;
  }
  .land_contact_us .ins_sec {
    padding: 70px 30px 40px 30px;
  }
  .land_contact_us {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .how_we_work.new .doctors_list .row .col:nth-child(3),
  .how_we_work.new .doctors_list .row .col:nth-child(4) {
    margin-bottom: 0px !important;
  }
  .how_we_work.new {
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .how_we_work.new .doctor-img {
    min-height: 300px;
  }
  .cmn-signup .sign_up_img {
    width: unset;
    height: unset;
    min-height: unset;
    max-height: unset;
  }
  .cmn-signup .cmn_input_sec {
    padding: 30px 20px;
  }
  .booking_completed .cmn-sec .sub_heading_txt {
    font-size: 15px;
  }
  .select-type .cmn_smlCard {
    width: 120px;
    min-height: 130px;
    padding: 10px;
  }
  .choose-required .require_card {
    width: 120px;
    min-height: 130px;
    padding: 10px;
  }
  .choose-required .require_sec {
    gap: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .landing_banner .ryt-sec .bigLeaf_img {
    /* height: 300px; */
  }
  .landing_banner .ryt-sec .StrokeTxt_img {
    top: -5px;
    right: 0px;
    width: 130px;
  }
  .who_we_are .left-sec:after {
    bottom: -50px;
    right: 0px;
    width: 100px;
  }
  .services_list .sec-1 .women-img {
    height: 300px;
    margin-top: 20px;
  }
  .services_list .sec-1,
  .services_list .sec-2,
  .services_list .sec-3 {
    min-height: unset;
    max-height: unset;
  }
  .services_list .sec-2 .child-img {
    margin-left: unset;
    max-width: unset;
    width: unset;
    margin-top: unset;
  }
  .services_list .sec-3 .surgical-img {
    margin-top: 20px;
  }
  .how_we_work .services_list .sec-3 {
    padding-bottom: unset;
  }
  .services_list .row .col1,
  .services_list .row .col2,
  .services_list .row .col3 {
    max-width: unset;
  }
  .services_list .row {
    align-items: unset;
  }
  .watch_story .flying-leaf:after {
    width: 110px;
    height: 100px;
    bottom: -40px;
    right: 5px;
  }
  .watch_story .ryt_arrow {
    height: 25px;
    bottom: 6px;
    right: 13px;
  }

  .how_we_work_new .service_leaf {
    height: 130px;
  }
  .how_we_work_new .cmn-sec {
    gap: 13px;
    align-items: end;
  }
  .how_we_work_new {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .how_we_work_new .service_lists .row .col:last-child {
    margin-bottom: 0px !important;
  }
  .how_we_work_new .service_lists .row .col:nth-child(5) {
    margin-bottom: 0px !important;
  }
  .land_contact_us .contact_us_img {
    max-width: 100%;
    margin-top: unset;
  }
  .land_contact_us {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .how_we_work.new .doctors_list .row .col:nth-child(3),
  .how_we_work.new .doctors_list .row .col:nth-child(4) {
    margin-bottom: 0px !important;
  }
  .how_we_work.new {
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .how_we_work.new .doctor-img {
    min-height: 300px;
  }
  .cmn-signup .cmn_input_sec {
    padding: 30px 30px;
  }
  .cmn-signup .sign_up_img {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: unset;
  }
  .booking_completed .cmn-sec .sub_heading_txt {
    font-size: 16px;
  }
  .select-type .cmn_smlCard {
    width: 120px;
    min-height: 130px;
    padding: 10px;
  }
  .choose-required .require_card {
    width: 120px;
    min-height: 130px;
    padding: 10px;
  }
  .choose-required .require_sec {
    gap: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .header .logo {
    width: 70px;
  }
  .header .custom-nav .header-textList a {
    font-size: 12px;
  }
  .header .register-btn {
    font-size: 10px;
    padding: 5px 15px;
  }
  .headerContents {
    gap: 15px;
  }
  .header .login-btn {
    font-size: 10px;
    padding: 5px 15px;
  }
  .headerContents .bell-icon {
    width: 19px;
  }
  .headerContents .srch_logo {
    width: 19px;
  }
  .header-search input {
    height: 26px;
    font-size: 12px;
    width: 170px;
    padding-left: 33px;
  }
  .header-search .search-logo {
    width: 18px;
    height: 18px;
    top: 4px;
  }

  .landing_banner .ryt-sec .bigLeaf_img {
    height: 400px;
  }
  .landing_banner .ryt-sec .StrokeTxt_img {
    top: -5px;
    right: 0px;
    width: 130px;
  }
  .cmn_land_subTxt {
    font-size: 41px;
    line-height: 43px;
  }
  .cmn_land_des {
    line-height: 20px;
    font-size: 15px;
  }
  .who_we_are .left-sec:after {
    bottom: -50px;
    right: 0px;
    width: 100px;
  }

  .services_list .sec-1 .women-img {
    /* height: 250px; */
    width: 200px;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
  }
  /* .services_list .sec-1,
  .services_list .sec-2,
  .services_list .sec-3 {
    min-height: unset;
    max-height: unset;
  } */
  .services_list .sec-2 .child-img {
    margin-left: unset;
    max-width: unset;
    width: unset;
    margin-top: unset;
  }
  .services_list .sec-3 .surgical-img {
    margin-top: 0px;
    width: 240px;
    margin-left: auto;
    margin-right: auto;
  }
  /* .how_we_work .services_list .sec-3 {
    padding-bottom: unset;
  } */
  /* .services_list .row .col1,
  .services_list .row .col2,
  .services_list .row .col3 {
    max-width: unset;
  } */
  .services_list .row {
    align-items: unset;
  }
  .services_list .sec-1 .title-txt,
  .services_list .sec-2 .title-txt {
    font-size: 22px;
  }
  .services_list .sec-1 .des,
  .services_list .sec-2 .des {
    font-size: 13px;
  }
  .services_list .sec-1,
  .services_list .sec-2,
  .services_list .sec-3 {
    min-height: 400px;
    max-height: 400px;
  }
  .how_we_work .services_list {
    margin-top: 30px;
  }
  .watch_story .ryt_arrow {
    height: 25px;
  }

  .watch_story .flying-leaf:after {
    width: 140px;
    height: 130px;
    bottom: -70px;
    right: -20px;
  }

  .how_we_work_new .service_leaf {
    height: 130px;
  }
  .how_we_work_new .cmn-sec {
    gap: 13px;
    align-items: end;
  }
  .how_we_work_new {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .how_we_work .flying_leaf::before {
    left: -55px;
  }
  .land_contact_us .contact_us_img {
    max-width: 100%;
    margin-top: unset;
  }
  .land_contact_us {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .land_contact_us .ins_sec {
    padding: 80px 50px 40px 70px;
  }
  .how_we_work.new .doctors_list .row .col:nth-child(3),
  .how_we_work.new .doctors_list .row .col:nth-child(4) {
    margin-bottom: 0px !important;
  }
  .how_we_work.new {
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .how_we_work.new .doctor-img {
    min-height: 250px;
  }
  .how_we_work.new .title-txt {
    font-size: 23px;
  }

  .cmn-signup .cmn_input_sec {
    padding: 30px 30px;
  }
  .cmn-signup .sign_up_img {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: unset;
  }
  .select-type .cmn_smlCard {
    width: 120px;
    min-height: 130px;
    padding: 10px;
  }
  .choose-required .require_sec {
    gap: 30px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .cmn-signup.otp-verification .sign_up_img,
  .cmn-signup.create-pwd .sign_up_img,
  .cmn-signup.login .sign_up_img {
    max-height: 100vh !important;
  }
  /* .cmn_smlCard {
    width: 130px;
    padding: 17px 17px;
    min-height: 150px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .cmn_smlCard .cmn-img {
    width: 50px;
    height: 50px;
  } */
}
@media (min-width: 1200px) and (max-width: 1250px) {
  .header .custom-nav .header-textList a {
    font-size: 14px;
  }
  .header .register-btn {
    font-size: 12px;
    padding: 5px 22px;
  }
  .header .login-btn {
    font-size: 12px;
    padding: 5px 22px;
  }
  .headerContents .bell-icon {
    width: 22px;
  }
  .headerContents .srch_logo {
    width: 22px;
  }
  .header-search input {
    height: 30px;
    font-size: 13px;
    width: 210px;
    padding-left: 36px;
  }
  .header-search .search-logo {
    width: 20px;
    height: 20px;
    top: 5px;
  }
  .cmn_land_subTxt {
    font-size: 41px;
    line-height: 43px;
  }
  .cmn_land_des {
    line-height: 20px;
    font-size: 15px;
  }
  .who_we_are .left-sec:after {
    bottom: -50px;
    right: 0px;
    width: 100px;
  }
}
@media (min-width: 1400px) and (max-width: 1799px) {
  .landing_banner .ryt-sec .bigLeaf_img {
    height: unset;
    min-width: 650px;
  }
  .landing_banner .ryt-sec .StrokeTxt_img {
    right: -170px;
  }
  .services_list .sec-3 .surgical-img {
    width: 340px;
  }
  .services_list .row {
    align-items: unset;
  }
  .watch_story .ryt_arrow {
    height: 30px;
    right: 6px;
  }

  .land_contact_us .contact_us_img {
    min-width: 650px;
    width: 650px;
    margin-top: -185px;
  }
  .land_contact_us .lft_col {
    display: flex;
    align-items: center;
  }
  /* .land_contact_us .cmnLeaf_headerTxt {
    font-size: 50px;
  }
  .land_contact_us .cmn_land_subTxt {
    font-size: 55px;
  } */
  .land_contact_us {
    padding-top: 140px;
  }
}
@media (max-width: 575px) {
  .dash-header-search {
    width: 100%;
  }
  .dash-header-search input {
    width: 100%;
    border-radius: 8px;
  }

  .rate_experience .cmn-steps .count {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    font-size: 12px;
  }
  .rate_experience .cmn-steps {
    gap: 15px;
  }
  .rate_experience .ftr_BtnSec {
    margin-left: 50px;
  }
  .dashboard_sec .dashboard-content {
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  .how_we_work_new .service_lists .row .col:last-child {
    margin-bottom: 0px !important;
  }
}
@media (max-width: 991px) {
  .headerContents {
    flex-direction: column;
    align-items: start;
    gap: 15px;
    margin-top: 10px;
  }
  .headerContents .header-textList {
    flex-direction: column;
    align-items: start;
    gap: 25px;
  }
  .headerContents.false {
    gap: 30px;
  }
  .who_we_are {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .watch_story {
    padding-top: 10px;
    padding-bottom: 30px;
  }
  .modalbox .cancelimg svg {
    max-width: 110px;
  }
}
@media (min-width: 992px) {
  .how_we_work_new .service_lists .row .col:nth-child(4),
  .how_we_work_new .service_lists .row .col:nth-child(5),
  .how_we_work_new .service_lists .row .col:nth-child(6) {
    margin-bottom: 0px !important;
  }
}
@media (max-width: 1199px) {
  /* .headerContents {
    flex-direction: column;
    align-items: start;
    gap: 15px;
    margin-top: 10px;
  }
  .headerContents .header-textList {
    flex-direction: column;
    align-items: start;
    gap: 15px;
  } */

  .dashboard_header .offcan-btn {
    margin-left: 17px;
    margin-top: 8px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }
  .dashboard_sec .left_side_sec {
    display: none;
  }
  .dashboard_sec .right_side_sec {
    width: 100% !important;
    padding: 0px;
  }
  .dashboard_sec .right_side_sec .dashboard_header {
    border-radius: 0px;
  }
  .sidebar_sec .logo_sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media (min-width: 1200px) {
  .sidebar_sec .logo_sec .close_offcanvas_logo {
    display: none;
  }
  .dashboard_header .offcan-btn {
    display: none;
  }
  .dashboard_header .mbl-logo {
    display: none;
  }
  .dashboard_header .navbar-brand {
    display: none;
  }
}
@media (max-width: 1399px) {
}
@media (max-width: 1799px) {
}
@media (min-width: 1800px) {
  .header .logo {
    width: 160px;
  }
  .headerContents {
    justify-content: end;
  }
  .headerContents.false {
    gap: 125px;
  }
  .headerContents .header-textList.false {
    gap: 50px;
  }
  .header-search input {
    height: 37px;
    width: 370px;
    font-size: 16px;
  }
  .header-search .search-logo {
    top: 7px;
  }
  .header .custom-nav .header-textList a {
    font-size: 17px;
  }
  .headerContents .header-textList {
    gap: 30px;
  }
  .header .register-btn {
    font-size: 16px;
    padding: 10px 30px;
  }
  .header .login-btn {
    font-size: 16px;
    padding: 10px 30px;
  }
  .headerContents .bell-icon {
    width: 30px;
  }
  .headerContents .srch_logo {
    width: 30px;
  }
  .headerContents .header-btns {
    gap: 23px;
  }
  .footer .heading-txt {
    font-size: 16px;
  }

  .landing_banner .ryt-sec .bigLeaf_img {
    min-width: 840px;
    height: unset;
  }
  .landing_banner .ryt-sec .StrokeTxt_img {
    position: absolute;
    top: -49px;
    right: -190px;
    width: 190px;
    z-index: 1;
  }

  .landing_banner .des {
    margin-top: 20px;
    margin-bottom: 30px !important;
  }
  .landing_banner .sub-heading-txt {
    margin-top: 10px;
  }
  .cmnLeaf_headerTxt::before {
    width: 123px;
    height: 107px;
    top: -39px;
    left: -67px;
  }
  .cmnLeaf_headerTxt {
    font-size: 66px;
  }
  .cmn_land_subTxt {
    font-size: 74px;
    line-height: 90px;
  }
  .cmn_land_des {
    font-size: 19px;
    line-height: 26px;
  }
  .cmn_land_btn {
    font-weight: 500 !important;
    padding: 9px 35px;
    font-size: 17px;
  }
  .land_custom_container {
    max-width: 90% !important;
  }
  .who_we_are .left-sec {
    padding-top: 80px;
  }
  .who_we_are .left-sec:after {
    width: 140px;
    height: 100px;
    bottom: -75px;
    right: -70px;
  }
  .services_list .sec-1 .title-txt,
  .services_list .sec-2 .title-txt {
    font-size: 35px;
    line-height: 70px;
  }
  .services_list .sec-1 .des,
  .services_list .sec-2 .des {
    font-size: 17px;
  }
  .services_list .sec-1,
  .services_list .sec-2,
  .services_list .sec-3 {
    max-height: 560px;
    min-height: 560px;
  }
  .services_list .sec-1 .women-img {
    height: 420px;
  }
  .services_list .row {
    align-items: center;
    justify-content: space-between;
  }
  .services_list .row .col1,
  .services_list .row .col2,
  .services_list .row .col3 {
    max-width: 490px;
  }
  .services_list .sec-2 .child-img {
    margin-left: -54px;
    max-width: 575px;
    width: 575px;
    margin-top: 54px;
  }
  .watch_story .ryt-sec {
    padding-left: 60px;
  }
  .how_we_work_new .service_leaf {
    height: unset;
  }
  .how_we_work_new .service_lists .title-txt {
    font-size: 35px;
  }
  .how_we_work_new .service_lists .des {
    font-size: 18px;
  }
  .how_we_work_new .cmn-sec {
    align-items: center;
  }
  .lit-scrollbar-track {
    background-color: transparent;
  }

  .cmn_inp_scroll::-webkit-scrollbar {
    width: 3px;
  }

  .cmn_inp_scroll::-webkit-scrollbar-thumb {
    background-color: var(--themeclr);
  }

  .cmn-signup .container {
    padding: 0px;
    margin: 0px;
  }
  .cmn-signup .row {
    padding: 0px;
    margin: 0px;
  }
  .cmn-signup .row .col {
    /* t-size: 55px; */
  }
  .land_contact_us {
    padding-top: 140px;
  }
  .footer .meet_doctor {
    width: 480px;
    height: 45px;
  }
  .how_we_work.new .title-txt {
    font-size: 30px;
  }
  .how_we_work.new .des {
    font-size: 18px;
  }

  .cmn_input_sec .logo {
    width: 160px;
  }
  .cmn_input_sec .heading_txt {
    font-size: 42px;
  }
  .cmn_input_label {
    font-size: 18px;
  }
  .cmn_input_field {
    height: 56px;
    font-size: 16px !important;
  }
  .cmn_inp_scroll {
    height: 700px;
  }
  .cmn_inp_btn button {
    height: 56px;
    font-size: 20px;
    padding: 20px !important;
  }
  .already_have {
    font-size: 17px;
  }
  .cmn-signup .cmn_input_sec {
    padding: 50px 130px;
  }
  .dash-header-search input {
    width: 700px;
  }
}

/* start of  hari haran css */

.cmn_input_field {
  background: var(--greenClr3) !important;
  border: 1px solid var(--greenClr4) !important;
}
.cmn_input_field {
  color: var(--darkGrayClr4) !important;
  font-size: 14px !important;
}

.cmn_input_field::placeholder {
  color: var(--darkGrayClr5) !important;
  opacity: 0.6 !important;
}
/* end of hariharan css */
