/* start of common css */

.container_95 {
  max-width: 95% !important;
}
.w_60 {
  width: 60%;
}

.mt_2rem {
  margin-top: 2rem;
}
.pt_2rem {
  padding-top: 2rem;
}

.pm_button_one {
  background: var(--darkGreenClr);
  border: 1px solid var(--darkGreenClr);
  color: var(--whiteClr);
  font-weight: 550;
  font-size: 13px;
  padding: 5px 25px;
  border-radius: 3px;
  transition: 0.3s;
  white-space: nowrap;
}
.pm_button_one:hover {
  background: transparent;
  border: 1px solid var(--darkGreenClr);
  color: var(--darkGreenClr);
}
.pm_button_two {
  background: var(--whiteClr);
  border: 1px solid var(--lightGrayClr);
  color: var(--grayClr);
  font-weight: 400;
  font-size: 13px;
  padding: 5px 25px;
  border-radius: 3px;
  transition: 0.3s;
}
.pm_button_two:hover {
  background: var(--grayClr);
  border: 1px solid var(--grayClr);
  color: var(--whiteClr);
}
.border_less_button {
  border: none;
  outline: none;
  background: transparent;
  color: var(--darkGreenClr);
  font-size: 15px;
}
.choose_file_button {
  background: var(--green1);
  color: var(--grayClr);
  border: none;
  outline: none;
  width: 120px;
  height: 100%;
  border: 1px solid var(--lightGrayClr);
  border-radius: 5px;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 0px;
}
.gren_btn {
  background: var(--darkGreenClr);
  color: var(--whiteClr);
  border: none;
  outline: none;
  padding: 5px 10px;
  border: 1px solid var(--darkGreenClr);
  border-radius: 3px;
  font-size: 13px;
  top: 0;
  right: 0;
}
.mdl_green_btn {
  background: var(--greenClr);
  border: 1px solid var(--greenClr);
  color: var(--whiteClr);
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  font-weight: 450;
}
.mdl_green_btn:disabled {
  background: var(--lightGrayClr10);
  border: 1px solid var(--lightGrayClr10);
  color: var(--lightGrayClr11);
  /* border-radius: 5px;
  padding: 8px 10px; */
}
.mdl_green_btn_sm {
  background: var(--greenClr);
  border: 1px solid var(--greenClr);
  color: var(--whiteClr);
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 450;
  transition: 0.3s;
}
.mdl_red_btn_sm {
  background: var(--redClr4);
  border: 1px solid var(--redClr4);
  color: var(--whiteClr);
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 450;
  transition: 0.3s;
}
.mdl_blue_btn_sm {
  background: var(--blueClr);
  border: 1px solid var(--blueClr);
  color: var(--whiteClr);
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 450;
  transition: 0.3s;
}
.mdl_gray_btn_sm {
  background: var(--lightGrayClr3);
  border: 1px solid var(--lightGrayClr6);
  color: var(--grayClr);
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 450;
  transition: 0.3s;
}
.mdl_green_btn_sm:hover,
.mdl_red_btn_sm:hover,
.mdl_blue_btn_sm:hover,
.mdl_gray_btn_sm:hover {
  transform: scale(98%);
}
.fw_450 {
  font-weight: 450;
}
.fw_550 {
  font-weight: 550;
}
.text_black_sm {
  color: var(--grayClr);
  font-size: 14px;
}
.text_black_md {
  color: var(--grayClr);
  font-size: 14px;
  font-weight: 550;
}
.text_black_lg {
  color: var(--darkGrayclr);
  font-weight: 700;
  font-size: 15px;
}
.text_green_lg {
  color: var(--darkGreenClr);
  font-weight: 700;
  font-size: 15px;
}
.text_green_md {
  font-size: 12px;
  color: var(--darkGreenClr);
  font-weight: 700;
  font-size: 15px;
}
.gray_text_sm {
  font-size: 14px;
  color: var(--lightGrayClr2);
}
.text_black_xs {
  color: var(--darkGrayclr);
  font-size: 13px;
}
.text_black_xs_bold {
  font-weight: 500;
  color: var(--darkGrayclr);
  font-size: 14px;
}
.text_black_xxs {
  color: var(--darkGrayclr);
  font-size: 12px;
}
.text_white_xs {
  font-size: 9px;
  color: var(--whiteClr);
}

.text_red_bg {
  background: var(--redClr3);
  padding: 8px 15px;
  color: var(--redClr2);
  font-size: 13px;
  width: fit-content;
  text-transform: capitalize;
  font-weight: 500;
}
.text_red_xs {
  color: var(--redClr6) !important;
}
.mdl_text_xs {
  color: var(--blackClr2);
  font-size: 12px;
}
.mdl_text_bold_sm {
  color: var(--blackClr2);
  font-size: 14px;
  font-weight: 500;
}
.mdl_text_bold_md {
  color: var(--blackClr3);
  font-weight: 700;
  font-size: 14px;
}
.mdl_text_bold_md span {
  text-transform: uppercase;
}
.mdl_gray_text_sm {
  color: var(--lightGrayClr8);
  font-size: 13px;
}
.mdl_title_sm {
  font-size: 17px;
  font-weight: 600;
}
.img_container_fit {
  min-width: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
  min-height: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
}

/* end of common css */

.primary_card {
  background: var(--whiteClr);
  border: 1px solid var(--lightGrayClr);
  padding: 10px;
  border-radius: 5px;
}

.requried_icon {
  color: var(--redClr);
}
.prm_textarea {
  background: var(--whiteClr);
  border: 1px solid var(--lightGrayClr);
  padding: 8px;
  font-size: 14px;
  color: var(--grayClr);
  min-height: 110px;
  max-width: 95%;
  border-radius: 5px;
}
.prm_textarea:focus-visible {
  outline: none;
}
.prm_textarea_md {
  background: var(--whiteClr);
  border: 1px solid var(--lightGrayClr);
  padding: 8px;
  font-size: 14px;
  color: var(--grayClr);
  min-height: 250px;
  max-width: 95%;
  border-radius: 5px;
}
.prm_textarea_md:focus-visible {
  outline: none;
}
.th_json {
  width: 100px;
}

/* start of modal css -1 */

.th_custom_modal {
  font-family: var(--poppins_font);
}
.th_custom_modal .modal-header {
  border: none;
  padding: 0px;
}
.th_custom_modal .modal-body {
  padding: 20px;
}
.th_custom_modal .circle_cls_button {
  right: -14px;
  top: -14px;
  background: var(--darkGreenClr);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  z-index: 2;
}

.th_custom_modal .circle_cls_button svg {
  color: var(--whiteClr);
}
/* end of modal css -1 */

/* start of modal css -2 */

.custom_modal_border {
  font-family: var(--poppins_font);
}
.custom_modal_border .modal-header {
  border: none;
  padding: 0px;
}
.custom_modal_border .modal-body {
  padding: 15px;
  /* padding-top: 35px; */
}

.modal_border {
  border: 1px solid var(--lightGrayClr);
  border-radius: 5px;
  padding: 18px;
}
.custom_modal_border .circle_cls_button {
  right: -14px;
  top: -14px;
  background: var(--darkGreenClr);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  z-index: 2;
}

.custom_modal_border .circle_cls_button svg {
  color: var(--whiteClr);
}
.mdl_logo {
  width: 130px;
}
.mdl_body_title {
  border-bottom: 1px solid var(--lightGrayClr);
}
.mdl_body_content {
  border-bottom: 1px solid var(--lightGrayClr);
}
.text_wt_border {
  border: 1px solid var(--lightGrayClr);
  padding: 10px;
}
.sign_img {
  width: 150px;
  max-width: 150px;
}

/* end of modal css -2 */

/* start of custom table bootstrap */

.custom_bs_table .table thead tr th {
  background: var(--lightGrayClr3);
  color: var(--lightGrayClr4);
  font-size: 15px;
  font-weight: 400;
  min-width: 170px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  vertical-align: middle;
}
.custom_bs_table .table tbody tr td {
  text-align: center;
  color: var(--darkGrayClr2);
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 15px;
  vertical-align: middle;
  border-bottom: none;
}
.custom_bs_table .table thead tr th:first-child {
  min-width: 100px;
}
.custom_bs_table .table {
  /* border-collapse: unset !important; */
}
.custom_bs_table .table thead tr {
  background: red !important;
}
.custom_bs_table .table tbody {
  border: 1px solid var(--lightGrayClr5);
}

/* end of custom table bootstrap */

.custom_label {
  color: var(--grayClr);
  font-size: 14px;
  padding-left: 0px;
}
.custom_input {
  border: 1px solid var(--lightGrayClr);
  background: var(--lightGrayClr3);
  height: 42px;
  border-radius: 5px;
  font-size: 13px;
  width: 100%;
  color: var(--grayClr);
  padding-left: 15px;
  padding-right: 15px;
}
.custom_input::placeholder {
  opacity: 0.6;
}
.choose_custom_input {
  border: 1px solid var(--lightGrayClr);
  background: var(--lightGrayClr3);
  height: 42px;
  border-radius: 5px;
  font-size: 13px;
  width: 100%;
  color: var(--grayClr);
  padding-left: 15px;
  /* padding-right: 15px; */
}
.choose_file {
  height: 100%;
  width: calc(100% - 120px);
}
.custom_input::placeholder {
  color: var(--grayClr);
}
.custom_input:focus-visible {
  outline: none;
}
.custom_textarea {
  border: 1px solid var(--lightGrayClr);
  background: var(--lightGrayClr3);
  border-radius: 5px;
  font-size: 13px;
  width: 100%;
  color: var(--grayClr);
  padding: 15px;
  min-height: 100px;
}
.custom_textarea::placeholder {
  color: var(--grayClr);
}
.custom_textarea:focus-visible {
  outline: none;
}
.choosen_file_name {
  width: 100%;
  max-width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.upload_image_wraper {
  min-width: 100px;
  width: 100px;
  max-width: 100px;
  min-height: 100px;
  height: 100px;
  max-height: 100px;
  border: 4px solid var(--lightGrayClr6);
  border-radius: 50%;
  background: var(--lightGrayClr7);
}
.upload_image_wraper img {
  object-fit: cover;
}

/* start of accordion */
.custom_accor .accordion-button:focus {
  box-shadow: none;
}
.custom_accor .accordion-button:not(.collapsed) {
  background-color: var(--lightGrayClr3);
  box-shadow: none;
  border-radius: 10px !important;
  border: 1px solid var(--lightGrayClr6) !important;
  color: var(--blackClr);
  font-weight: 550;
}
.custom_accor .accordion-button {
  background-color: var(--lightGrayClr3);
  box-shadow: none;
  border-radius: 10px !important;
  border: 1px solid var(--lightGrayClr6) !important;
  color: var(--blackClr);
  font-weight: 550;
  font-size: 12px;
}
.custom_accor .accordion-header {
}
.custom_accor .accordion-item {
  margin-bottom: 1rem;
  border: none;
}
.custom_accor .accordion-button:not(.collapsed)::after {
}
.custom_accor .accordion-body {
  font-size: 13px;
}
.custom_accor .accordion-body.doctor_education_body {
  border: 1px solid var(--lightGrayClr6);
  margin-top: 1rem;
  border-radius: 10px;
}

/* end of accordion */

/* start of custom checkbox */

.custom_chckbox .form-check-input[type="checkbox"] {
  margin-top: 0px !important;
}
.custom_chckbox .form-check-label {
  color: var(--darkGreenClr);
  font-size: 14px;
  font-weight: 500;
}

/* end of custom checkbox */

/* start of custom radio */

.custom_radio_button .container input:checked ~ .checkmark {
  background-color: var(--darkGreenClr);
  border: none;
}
.custom_radio_button .container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.custom_radio_button .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.custom_radio_button .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border-radius: 50%;
  border: 1px solid var(--blackClr);
}

/* On mouse-over, add a grey background color */
.custom_radio_button .container:hover input ~ .checkmark {
  /* background-color: #ccc; */
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.custom_radio_button .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom_radio_button .container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom_radio_button .container .checkmark:after {
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
}

/* end of custom radio */

/* start of custom toggle */

.custom_toggle .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 21px;
}

.custom_toggle .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom_toggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custom_toggle .slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

.custom_toggle input:checked + .slider {
  background-color: var(--darkGreenClr);
}

.custom_toggle input:focus + .slider {
  box-shadow: 0 0 1px var(--darkGreenClr);
}

.custom_toggle input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* end of custom toggle */

/* start of react select */

.custom_react_select__control {
  background: transparent !important;
  border: 1px solid var(--lightGrayClr) !important;
  min-height: 50px !important;
  padding-top: 0 !important;
  border-radius: 25px !important;
  padding-left: 10px;
  padding-right: 10px;
}
.custom_react_select__control--is-focused,
.custom_react_select__control--menu-is-open {
  box-shadow: none !important;
}
.custom_react_select__indicator-separator {
  display: none !important;
}
.custom_react_select__placeholder,
.custom_react_select__single-value {
  color: var(--grayClr) !important;
  font-size: 13px !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
}
.custom_react_select__indicator {
  padding: 0px !important;
  padding-left: 3px !important;
}
.custom_react_select__indicator svg {
  width: 16px;
  fill: var(--grayClr);
}
.custom_react_select__value-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.custom_react_select__menu {
  /* background-color: var(----lightGrayClr3) !important; */
  border: 1px solid var(--lightGrayClr) !important;
  box-shadow: none !important;
  border-radius: 25px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.custom_react_select__option {
  padding-top: 8px !important ;
  font-size: 12px !important;
  cursor: pointer !important;
}
.custom_react_select__option--is-focused,
.custom_react_select__option--is-selected {
  background: transparent !important;
  color: var(--darkGreenClr) !important;
  font-weight: 500 !important;
  opacity: 1 !important;
}
.custom_react_select__menu-list {
  padding: 0px !important;
}

/* end of react select */

.doctor_custom_input {
  background: transparent !important;
  border: 1px solid var(--lightGrayClr) !important;
  min-height: 50px !important;
  border-radius: 25px !important;
  padding-left: 10px;
  padding-right: 10px;
  color: var(--grayClr);
  font-size: 13px;
}
.doctor_custom_input::placeholder {
  color: var(--grayClr);
  font-size: 13px;
}
.doctor_custom_input:focus-visible {
  outline: none;
}
.link_span {
  color: var(--darkGreenClr);
  text-decoration: underline;
}
.uplaod_container {
  min-height: 200px;
  border: 1px solid var(--lightGrayClr6);
  border-radius: 15px;
  background: var(--lightGrayClr3);
}
.upload_prescription {
  border: 1px solid var(--lightGrayClr6);
  border-radius: 15px;
  background: var(--lightGrayClr3);
  padding: 15px 25px;
}
.pres_download {
  top: 35px;
  right: 26px;
}
/* .cmn_input_sec_new {
  padding: 30px 70px;
  background: #edfffb;
}
.cmn_input_sec_new .logo {
  width: 130px;
}
.cmn_input_sec_new .uplaod_container {
  background: transparent;
}
.cmn_input_sec_new .upload_prescription {
  background: transparent;
} */
.cmn-signup .cmn_input_sec_new.cmn_input_sec div {
  width: unset !important;
}
.edu_qual .uplaod_container {
  background: transparent;
}
.edu_qual .upload_prescription {
  background: transparent;
}

.error_msg {
  color: var(--redClr);
  font-size: 14px;
}

.custom_tabs_wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
}
.custom_tabs {
  background-color: #f7f7f7;
  border-color: #707070;
  font-size: 16px;
  border-radius: 10px;
  color: #161c2d;
  padding: 8px 24px;
  box-shadow: none;
  border: 1px solid var(--grayClr);
  text-decoration: none;
}
.custom_tabs.active {
  background: var(--darkGreenClr);
  border-color: var(--darkGreenClr);
  color: var(--whiteClr);
}

/* start of waiting room modal */

.custom_modal_pads .modal-body {
  font-family: var(--poppins_font);
  padding: 0px;
}
.custom_modal_pads .modal-content {
  border: 0px;
}
.top_border_green {
  width: 100%;
  height: 10px;
  background: var(--greenClr);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.mdl_appoint_dtls {
  background: var(--greenClr2);
  padding: 10px;
}
.mdl_pfl_section {
  padding: 15px 10px;
  border-bottom: 1px solid var(--lightGrayClr9);
}
.mdl_profile_wraper {
  min-width: 60px;
  width: 60px;
  max-width: 60px;
  min-height: 60px;
  height: 60px;
  max-height: 60px;
}
.mdl_profile_wraper img {
  object-fit: cover;
}
.mdl_waiting_json {
  width: 200px;
}
.mdl_terms_cntn {
  padding: 20px 30px;
}
.mdl_checkbox .form-check-input {
  background-color: var(--greenClr2) !important;
  border: 1px solid var(--greenClr) !important;
}
.mdl_checkbox .form-check-input:checked {
  background-color: var(--greenClr) !important;
}
/* end of waiting room modal */

/* start of waiting area */

.waiting_section {
  background: var(--blackClr4);
  font-family: var(--poppins_font);
  color: var(--whiteClr);
}
.custom_video_player .ytp-youtube-button.ytp-button.yt-uix-sessionlink {
  display: none !important;
}
.faq_bg {
  background: var(--darkGrayClr3);
  border: 1px solid var(--lightGrayClr12);
  border-radius: 20px;
  padding: 20px 15px;
}
.custom_accor.waiting_custom_accor {
  padding-right: 10px;
}
.waiting_custom_accor.custom_accor .accordion-item {
  background: transparent;
}
.waiting_custom_accor.custom_accor .accordion-button {
  background: var(--blackClr4);
  border: none !important;
  color: var(--whiteClr);
}
.waiting_custom_accor.custom_accor .accordion-button::after {
  filter: brightness(99);
}
.waiting_custom_accor.custom_accor .accordion-body {
  color: var(--lightGrayClr2);
  font-size: 15px;
}
.custom_video_player div:first-child {
  /* min-height: 300px;
  height: 85vh;
  max-height: 85vh;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  border-radius: 20px;
  overflow: hidden; */
  min-width: 100%;
  width: 100%;
  max-width: 100%;
}
.timeout_wraper {
  background: var(--redClr5);
  color: var(--redClr4);
  font-weight: 600;
  padding: 5px 13px;
  border-radius: 8px;
}
/* end of waiting area */

.auth_pass_wrap {
  padding: 0.375rem 0.75rem;
  background: var(--greenClr3);
  border: none;
  outline: none;
  height: 43px;
  font-size: 14px;
  color: var(--darkGrayClr4);
  border-radius: 5px;
  border: 1px solid var(--greenClr4);
}
.auth_pass_wrap input {
  background: transparent;
  border: none;
  outline: none;
  color: var(--darkGrayClr4);
  font-size: 14px;
  width: 100%;
  opacity: 1;
}
.auth_pass_wrap input::placeholder {
  color: var(--darkGrayClr4);
  font-size: 14px;
  opacity: 0.6;
}
.auth_pass_icons {
  border: none;
  outline: none;
  background: transparent;
  height: 100%;
}
.auth_pass_icons svg {
  fill: var(--darkGrayClr4);
}

.cmn-signup {
  font-family: var(--poppins_font);
}

.customDatePicker input {
  border: 1px solid var(--greenClr4) !important;
  background-color: var(--greenClr3) !important;
  color: var(--darkGrayClr4) !important;
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px !important;
  height: 43px;
  width: 160px;
  outline: none;
}
.customDatePicker input::placeholder {
  opacity: 0.6 !important;
}
.customDatePicker .react-datepicker__view-calendar-icon {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.customDatePicker .calendar_logo {
  /* padding: 0px !important; */
}

.customDatePicker .react-datepicker-popper {
  /* right: 0px; */
  /* left: 0px; */
}

.customDatePicker .react-datepicker-wrapper {
  width: 100%;
}
.customDatePicker .react-datepicker__input-container input {
  width: 100%;
}

.customDatePicker .react-datepicker {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 6px #00000029;
  width: 100%;
  margin-bottom: 0;
  padding: 9px 9px 9px;
  margin-top: 4px;
  border: none;
}

.customDatePicker .react-datepicker__header {
  background-color: transparent;
}

.customDatePicker .react-datepicker__day--selected {
  background-color: #033e30;
  color: #fff;
}
.customDatePicker .react-datepicker__day--selected:hover {
  background-color: #033e30;
  color: #fff;
}
.customDatePicker .react-datepicker__triangle {
  display: none;
}

.earning_page .choose_date {
  display: flex;
  align-items: center;
  gap: 20px;
}
.customDatePicker.acc_settings input {
  border: 1px solid var(--lightGrayClr) !important;
  background-color: var(--lightGrayClr3) !important;
  color: var(--darkGrayClr4) !important;
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 10px !important;
  height: 43px;
  width: 100%;
  outline: none;
}

/* start of manual graph */

.mangra_overflow_align {
  width: 100%;
  margin-top: 15px;
  padding-bottom: 20px;
}
.manual_graph_single .single_square {
  height: 20px;
  width: 12px;
  min-width: 12px;
  background-color: lightgrey;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.man_grp_sng_gap {
  gap: 7px;
  margin-bottom: 7px;
}
.manualgra_yaxis_txt {
  color: var(--darkGreenClr);
  font-size: 13px !important;
  font-weight: 600;
  min-width: 30px;
}
.manual_graph_timing {
  color: var(--darkGreenClr);
  font-size: 13px !important;
  font-weight: 600;
  min-width: 30px;
}
.mid_frm_lft {
  margin-left: 26px;
}
.four_frm_lft {
  margin-left: 3px;
}
.egt_frm_lft {
  margin-left: 44px;
}
.lnc_frm_lft {
  margin-left: 44px;
}
.frpm_frm_lft {
  margin-left: 36px;
}
.egtpm_frm_lft {
  margin-left: 46px;
}
.manual_graph_single .single_square .test111 {
  background-color: #d3d3d3 !important;
  height: 20px;
  max-height: 20px;
  width: 25%;
}
.manual_graph_single .single_square .timing_div {
  background-color: var(--darkGreenClr);
  height: 20px;
  max-height: 20px;
  width: 25%;
}
.mangra_whole_ttl {
  color: #232222;
  font-size: 18px;
  font-weight: 700;
}
.title_percent_ttl {
  min-width: 280px;
}
.show_days_div {
  min-width: 20px;
}
.date_min_widt {
  min-width: 160px;
  width: 160px;
  max-width: 160px;
  min-height: 40px;
}
.customDatePicker .rdtPicker {
  min-width: 160px;
  margin-top: 10px;
  background: var(--greenClr3);
  border: 1px solid var(--greenClr4);
  border-radius: 5px;
}
.customDatePicker .rdtCounter {
  font-size: 15px;
}
.customDatePicker .rdtCounter .rdtBtn:hover {
  background: var(--greenClr4);
}
.customDatePicker .form-control:focus {
  box-shadow: none;
}
.form-check-input {
  cursor: pointer;
}
.form-check-input:focus {
  box-shadow: none !important;
}
/* end of manual graph */

/* media */

@media (min-width: 576px) {
  .th_custom_modal .modal-body {
    padding: 30px;
  }
  .primary_card {
    padding: 20px;
  }
  .mdl_waiting_json {
    width: 280px;
  }
}

@media (min-width: 992px) {
  .prm_textarea,
  .prm_textarea_md {
    max-width: 90%;
  }
  .text_black_lg {
    font-size: 17px;
  }
  .text_green_lg {
    font-size: 17px;
  }
  .text_green_md {
    font-size: 14px;
  }
  .text_black_md {
    font-size: 16px;
  }
  .custom_accor .accordion-button {
    font-size: 14px;
  }
  .custom_video_player div:first-child {
    min-height: 85vh;
    height: 85vh;
    max-height: 85vh;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    border-radius: 20px;
    overflow: hidden;
  }
  .faq_bg {
    min-height: 85vh;
    height: 85vh;
    max-height: 85vh;
  }
  .custom_accor.waiting_custom_accor {
    max-height: 80vh;
    padding-right: 10px;
    overflow-y: scroll;
  }
}
@media (min-width: 1200px) {
  .ps_xl_2rem {
    padding-left: 2rem !important;
  }
  .pe_xl_2rem {
    padding-right: 2rem !important;
  }
  .screen_ht {
    min-height: 100vh;
    height: 100vh;
    max-height: 100vh;
  }
}

@media (max-width: 1199px) {
  .custom_container_padding {
    padding-left: 17px;
    padding-right: 17px;
  }
}

/* @media (max-width: 575px) {
  .gren_btn.pres_download {
    padding: 3px;
    font-size: 9px;
    top: 20px;
    right: 14px;
  }
  .gren_btn.pres_download img {
    width: 10px;
  }
} */

@media (width < 992px) {
  .mangra_overflow_align.mangra_overflow_align_new {
    overflow-x: auto;
  }
}
@media (width < 576px) {
  .mangra_overflow_align {
    overflow-x: auto;
  }
}
