.flexbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .appointments {
  padding: 80px 20px 60px 200px;
} */

.appointments .h4 {
  font-size: 22px;
  color: #000;
  font-weight: 700;
  margin-bottom: 0;
}

.appointments .back {
  font-size: 16px;
  color: #000;
  text-decoration: none;
}

.appointments .nav-tabs {
  border-bottom: 0px;
  gap: 24px;
  margin: 35px 0px;
}

.appointments .nav-tabs .nav-link {
  background-color: #f7f7f7;
  border-color: #707070;
  font-size: 16px;
  border-radius: 10px;
  color: #161c2d;
  padding: 8px 24px;
  box-shadow: none;
}

.appointments .nav-tabs .nav-link.active {
  background-color: #033e30;
  color: #fff;
  border-color: #033e30;
}

.appointments .nav-tabs .nav-link:is(:hover, :focus) {
  background-color: #033e30;
  color: #fff;
  border-color: #033e30;
}

.appointments .text-green {
  color: #4caf50;
}
.appointments .text-blue {
  color: #2798f7;
}

.appointments .colorbox {
  display: flex;
  align-items: center;
  gap: 18px;
}

.appointments .colorbox .box {
  width: 45px;
  height: 45px;
  display: grid;
  place-content: center;
  border-radius: 50%;
}

.appointments .colorbox .green,
.btn-green {
  background-color: #4caf50;
}
.btn-green:hover {
  border: 1px solid #4caf50;
  color: #4caf50 !important;
}
.appointdetailsbox .btn.btn-green:hover {
  color: #4caf50 !important;
}
.appointments .colorbox .violet {
  background-color: #6f42c1;
}
.appointments .colorbox .red {
  background-color: #ff4141;
}

.btn-violet {
  background-color: #6f42c1 !important;
  border-color: #6f42c1 !important;
}

.btn-red {
  background-color: #ff4141 !important;
  border-color: #ff4141 !important;
}

.appointments .box {
  color: #fff;
  font-size: 20px;
}

.appointments .patientsbox {
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-wrap: wrap;
  gap: 20px;
}

.appointments .flex {
  display: flex;
  align-items: center;
}

.appointments .fleximg {
  gap: 12px;
}

.appointments .flextime {
  gap: 12px;
  align-items: start;
}

.appointments .flextime .flex {
  gap: 10px;
}

.appointments .patientimg {
  width: 100%;
  max-width: 75px;
  border-radius: 10px;
}

.appointments .patientsbox small {
  display: block;
}

.appointments .patientsbox p {
  margin-bottom: 6px;
  font-size: 16px;
  color: #161c2d;
  font-weight: 500;
}

.appointments .t-darkgreen {
  color: #033e30;
}

.appointments .patientsbox p:last-child {
  margin-bottom: 0;
}
.appointments .patientsbox p span {
  display: inline-block;
  vertical-align: middle;
}

.btn.btn-details {
  background-color: #f7f7f7;
  color: #161c2d;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 22px;
  border: 0.5px solid #d5d5d5;
  border-radius: 5px;
  line-height: normal;
}

.patientsappointment {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 6px #00000029;
  padding: 12px 20px;
}

.patientsappointment:not(:last-child) {
  margin-bottom: 24px;
}

.appointdetailsbox .patientsappointment {
  display: flex;
  align-items: start;
  padding: 25px 20px;
}

.appointdetailsbox .patientsappointment > div:first-child {
  width: 15%;
}
.appointdetailsbox .patientsappointment > div:last-child {
  width: 85%;
  padding-left: 12px;
}

/* .appointdetailsbox .patientsbox {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
} */

.appointdetailsbox .patientsbox {
  align-items: normal;
}

.appointdetailsbox .profile {
  width: 100%;
  max-width: 200px;
  border-radius: 10px;
}

.appointdetailsbox .flextime {
  gap: 8px;
}

.appointdetailsbox .patientsbox > div {
  margin-bottom: 60px;
  padding: 0px 10px;
}

.appointdetailsbox p {
  margin-bottom: 10px;
  color: #161c2d;
}

.appointdetailsbox p:last-child {
  margin-bottom: 0;
}

.appointdetailsbox small {
  padding-bottom: 7px;
  font-size: 15px;
}

.appointdetailsbox p span {
  padding-bottom: 7px;
  font-weight: 500;
}

.appointdetailsbox .btn {
  color: #fff !important;
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  padding: 7px 18px;
}

.appointdetailsbox .btn span,
.appointments .patientsbox p svg {
  position: relative;
  top: -2px;
}

.appointdetailsbox .btn-violet {
  margin-right: 24px;
}

.appointdetailsbox .appointdetails {
  padding-bottom: 150px;
}

.appointments .formbox {
  background-color: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
  padding: 24px;
  margin-top: 30px;
}

.appointments .formbox .btn-details {
  padding: 8px 26px;
}

.bgdarkgreen {
  background-color: #033e30 !important;
  border-color: #033e30 !important;
  color: #fff !important;
}

.bgdarkgreen:hover {
  background-color: transparent !important;
  color: #161c2d !important;
}

.appointments .formbox .formhead {
  color: #202020;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}

.appointments .formbox .form-label {
  font-size: 16px;
}

.appointments .formbox textarea {
  border-color: #dcdcdc;
  height: 100px;
  resize: none;
}

.appointments .formbox textarea:focus {
  box-shadow: none;
}

.appointmentsdate .datebox {
  justify-content: start;
  gap: 20px;
  align-items: normal;
}
.appointmentsdate .patientsappointment {
  width: 100%;
  margin-bottom: 0;
  padding: 24px;
}

.appointmentsdate .gridbox {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;
}

.appointmentsdate .datebox .dateboxtwo > div:not(:last-child) {
  margin-bottom: 24px;
}

.appointmentsdate .formbox {
  max-width: 1100px;
}

.patientsappointment .react-calendar {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  width: 100%;
  padding: 20px 14px 25px;
  height: 100%;
  font-family: "Poppins", sans-serif;
}

.patientsappointment .react-calendar button {
  color: #1d2634;
  font-weight: 600;
}

.patientsappointment .react-calendar__navigation__next2-button,
.patientsappointment .react-calendar__navigation__prev2-button {
  display: none;
}

.patientsappointment .react-calendar__navigation__label {
  flex-grow: inherit !important;
  padding: 0px 20px;
}

.patientsappointment .react-calendar__navigation {
  justify-content: center;
  margin-bottom: 24px;
}

.patientsappointment .react-calendar__navigation__next-button,
.patientsappointment .react-calendar__navigation__prev-button {
  font-size: 20px;
}

.patientsappointment abbr[title] {
  text-decoration: none;
  color: #bebebe;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
}

.patientsappointment
  .react-calendar
  button.react-calendar__month-view__days__day--neighboringMonth {
  color: #bebebe;
}

.patientsappointment .react-calendar button.react-calendar__tile--now,
.react-calendar__tile--active:enabled,
.react-calendar__tile--active,
.react-calendar__tile--active {
  background: transparent !important;
  /* color: #fff; */
  border-radius: 5px;
  /* width: 35px;
  height: 55px;
  display: grid;
  place-content: center; */
}

.patientsappointment .react-calendar button.react-calendar__tile--now abbr,
.react-calendar__tile--active:enabled:hover abbr,
.react-calendar__tile--active:enabled:focus abbr,
.react-calendar__tile--active abbr {
  background-color: #033e30;
  color: #fff;
  /* width: 35px;
  height: 35px;
  display: grid;
  place-content: center; */
  border-radius: 5px;
  padding: 5px 10px;
}

.patientsappointment.calendarbox {
  padding: 16px;
}

.patientsappointment .react-calendar__month-view__days button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 55px;
  display: grid;
  place-content: center;
}

.patientsappointment .react-calendar__tile:enabled:hover,
.patientsappointment .react-calendar__tile:enabled:focus {
  background-color: transparent;
}

.doctors .doctorimg,
.doctors .doctoricon,
.doctorsdetail .doctorimg {
  width: 100%;
  max-width: 65px;
  display: block;
  margin: auto;
}

.doctors .doctoricon {
  max-width: 30px;
  margin-top: -15px;
}

.doctors {
  margin-top: 35px;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, 1fr);
}

.doctors .patientsappointment {
  padding: 18px 20px;
  margin-bottom: 0;
}

.doctors .flexbox {
  justify-content: start;
  align-items: start;
  gap: 12px;
}

.doctors .doctorsubname,
.doctorsdetail .doctorsubname {
  display: block;
  color: #8e97a5;
  font-size: 15px;
}

.doctors .doctorname,
.doctorsdetail .doctorname {
  color: #111111;
  margin-bottom: 7px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
}

.appointments .doctors .btn-details {
  font-size: 14px;
  padding: 10px 16px;
}

.doctors .btntop {
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.choosedoctors .formbox {
  max-width: 100%;
}

.doctorsdetail .formbox {
  max-width: 1000px;
}

.docdetails {
  margin-top: 50px;
}
.docdetails .detailimg {
  width: 100%;
}

.doctorsdetail .doctorimg {
  max-width: 110px;
  margin: 0px 0px 12px;
}

.content {
  color: #202020;
  font-size: 16px;
  margin-bottom: 0;
  line-height: 27px;
}

.doctorsdetail .treatments {
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 20px;
}

.doctorsdetail .treatmentslist {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 20px;
  column-count: 3;
}

.doctorsdetail .treatmenthead {
  color: #202020;
  display: block;
  font-size: 18px;
}

.doctorsdetail .treatmentslist span {
  color: #033e30;
  font-size: 16px;
  padding-left: 12px;
}

.doctorsdetail .treatmentslist li img {
  width: 100%;
  max-width: 18px;
}

.doctorsdetail .treatmentslist li {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.doctorsdetail .docprofile {
  margin-top: -50px;
  padding-left: 20px;
}

.doctorsdetail .docprofile .row {
  align-items: end;
}

label {
  color: #1d2634;
  font-size: 16px;
  padding-left: 10px;
}
.doctorsdetail .bottomcontent label {
  color: #033e30;
  text-decoration: underline;
}

.form-check-input:checked {
  background-color: #033e30;
  border-color: #033e30;
}

.form-check {
  display: flex;
  align-items: center;
}
.doctorsdetail .bottomcontent .form-check {
  margin: 35px 0px;
}

.form-check .form-check-input {
  width: 24px;
  height: 24px;
}

.form-check .form-check-input:focus {
  box-shadow: none;
  border-color: #dee2e6;
}

.doctorsdetail .bottomcontent .btn {
  padding: 8px 25px;
  font-size: 15px;
  color: #fff;
}

.modalbox {
  font-family: "Poppins", sans-serif;
}

.modalbox .booking {
  color: #343434;
  font-size: 22px;
  font-weight: 500;
}
.modalbox .bookquote {
  color: #8e97a5;
  font-size: 16px;
  margin: 10px 0px 16px;
}

.modalbox .modal-body {
  padding: 24px;
  text-align: center;
}

.modalbox .btn {
  padding: 8px 22px;
  font-size: 15px;
  line-height: 21px;
}

.modalbox .successfulimg svg {
  width: 100%;
  max-width: 220px;
}

.modalbox.deletecare .modal-body {
  text-align: start;
}

.appointments .form-control {
  padding: 12px 12px;
  border-color: #d5d5d5;
}

.appointments .form-control:focus {
  box-shadow: none;
}

.appointments .form-select {
  padding: 10px 12px;
  border-color: #dcdcdc;
}

.appointments .form-control,
.appointments .form-select {
  color: #343434;
  font-size: 15px;
}

.appointments .form-select:focus {
  box-shadow: none;
  border-color: #dee2e6;
}

.doctorreschedule label {
  text-transform: capitalize;
}

.doctorreschedule .slotsbox .slothead {
  font-size: 16px;
  font-weight: 600;
  color: #343434;
  margin-bottom: 20px;
}

.doctorreschedule .slotsbox .slotbottom {
  border-top: 1px solid #e7e7e7;
  padding: 20px;
}

.doctorreschedule .dateboxtwo {
  padding: 0px;
}

.doctorreschedule .patientsappointment {
  width: 50%;
}

.doctorreschedule .slotsbox .slotbody {
  padding: 20px;
}

.doctorreschedule .slotsbox {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.doctorreschedule .slotsbox .form-check:not(:last-child) {
  margin-bottom: 18px;
}

.doctorreschedule .slots .btn {
  font-size: 12px;
}
.doctorreschedule .slots .slottext {
  font-size: 14px;
  color: #033e30;
  font-weight: 500;
}
.doctorreschedule .slots .bgdarkgreen {
  background-color: #033e30 !important;
  color: #fff !important;
}

.form-control:disabled {
  background-color: #f7f7f7;
}

.doctorreschedule .slots .input-group-text {
  background-color: #033e3017;
  border: 0px;
}
.doctorreschedule .slots .form-control {
  color: #1d2634;
  border: 0px;
}

.doctorreschedule .slots .slottime {
  gap: 30px;
}

.doctorreschedule .slotsbox .accordion-button {
  padding: 0px;
  display: inline-block;
  background-color: transparent;
  box-shadow: none;
}
.doctorreschedule .slotsbox .accordion-button:focus {
  box-shadow: none;
}
.doctorreschedule .slotsbox .accordion-button::after {
  background-image: none;
  width: auto;
  height: auto;
}
.doctorreschedule .slotsbox .accordion-item {
  background-color: transparent;
  border: 0px;
}

.doctorreschedule .slotsbox .btn-red {
  color: #fff;
}

.doctorappointment .appointments .nav-tabs,
.doctorappointmentdetail .appointments .nav-tabs {
  margin-bottom: 35px !important;
}

.doctorappointment .appointments .patientsbox > div:last-child {
  flex-grow: 0.4;
  text-align: end;
}

.doctorappointment .appointments .colorbox {
  justify-content: end;
}

.loginsettings .accordion {
  margin-top: 40px;
}

.loginsettings .custom_accor .accordion-body {
  padding: 20px 24px 10px;
}

.loginsettings .form-control {
  border-radius: 50px;
  padding-left: 18px;
  padding-right: 18px;
}

.loginsettings .content {
  font-size: 14px;
  line-height: 25px;
}

.supporttable .table {
  vertical-align: middle;
  margin-bottom: 0;
}
.supporttable .table thead {
  background-color: #f7f7f7;
}
.supporttable .table thead th,
.table tbody tr td {
  font-size: 16px;
  font-weight: 400;
  color: #7e7e89;
  text-transform: capitalize;
  background-color: transparent;
  box-shadow: none;
  padding: 12px 24px;
  text-align: center;
}

.supporttable .table tbody tr td {
  color: #161616;
  border-bottom: 0px;
  font-weight: 500;
}

.supporttable .table tbody tr:last-child {
  border: 0px;
}

.supporttable {
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  margin-top: 25px;
}

.supportaddress .table td .tableimg {
  width: 100%;
  max-width: 50px;
}

.supportaddress .table tbody td .flexbox {
  justify-content: center;
  gap: 10px;
}

.supporttable .table tbody td svg {
  color: #033e30;
  width: 100%;
  max-width: 20px;
  height: 100%;
}

.supporttable .table tbody td .btn {
  padding: 0;
  background-color: transparent;
  line-height: 0;
  font-size: 20px;
  box-shadow: none;
  border: 0px;
}

.bg-green {
  background-color: #e2f2e2;
  color: #4caf50;
}

.bg-red {
  background-color: #f2e2e2;
  color: #ff2424;
}

.supportaddress .table tbody td .status {
  padding: 9px 30px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 500;
}

.appointmentsdate .dateboxtwo p {
  font-size: 15px;
}

.appointmentsdate .dateboxtwo .gridbox .bgdarkgreen:hover {
  background-color: #033e30 !important;
  color: #fff !important;
}

.modalbox.deleteticket .modal-body {
  text-align: start;
}

.modalbox.deleteticket .booking.content {
  font-size: 18px;
}

.modalbox.deleteticket .content {
  font-size: 15px;
  line-height: 25px;
}

.reviewratings .greybox .h5 {
  font-size: 34px;
  margin-bottom: 0;
  color: #000;
  font-weight: 600;
}

.reviewratings .greybox .content {
  color: #000;
  font-weight: 500;
  margin-bottom: 15px;
}

.reviewratings .greybox {
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 15px 20px;
}

.reviewratings .appointments .formbox {
  border: 0px;
  padding: 0;
}

.reviewratings .all-ratings {
  display: flex;
  align-items: center;
  gap: 10px;
}

.reviewratings .all-ratings svg {
  color: #f8b84e;
  font-size: 20px;
}

.reviewratings .all-ratings .star-unfill {
  color: #e8e8e8;
}

.reviewratings .greybox .flexbox {
  justify-content: start;
  gap: 25px;
}
.reviewratings .ratingbox .flex {
  display: flex;
  align-items: center;
  gap: 15px;
}

.reviewratings .ratingbox .reviewername {
  color: #000;
  font-size: 16px;
  display: block;
  margin-bottom: 4px;
}
.reviewratings .ratingbox .reviewerdate {
  color: #5a5a5a;
  font-size: 14px;
}

.reviewratings .ratingimg {
  width: 100%;
  max-width: 60px;
  border-radius: 10px;
}

.reviewratings .ratingbox {
  border: 1px solid #e2e2e2;
  border-radius: 10px;
}

.reviewratings .ratingboxhead {
  padding: 16px;
  border-bottom: 1px solid #e2e2e2;
}

.reviewratings .ratingsboxbody {
  padding: 18px 30px;
  text-align: center;
}
.reviewratings .ratingsboxbody .content {
  color: #202020;
  font-size: 15px;
}

.reviewratings .ratingsboxbody .ratingsubname {
  font-size: 15px;
  color: #161c2d;
  font-weight: 500;
  display: block;
  margin-top: 15px;
}

.reviewratings .formbox > div:not(:last-child) {
  margin-bottom: 24px;
}

.superadmindashboard .greenline {
  border-color: #4caf50;
}
.superadmindashboard .orangeline {
  border-color: #fd7e14;
}
.superadmindashboard .violetline {
  border-color: #6f42c1;
}

.admincard .admincardbox {
  background-color: #fff;
  box-shadow: 0px 2px 4px #00000029;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 245px;
  border-radius: 7px;
  border: 1px solid #e7e7e7;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.admincard {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.admincard .admincardname {
  color: #161c2d;
  font-size: 16px;
  display: block;
  margin-top: 15px;
}

.admincard .admincardimg {
  width: 100%;
  max-width: 140px;
}

.admincardbox .hicons {
  width: 38px;
  height: 38px;
  display: grid;
  place-content: center;
  color: #fff;
  font-size: 17px;
  border-radius: 50px;
  margin-bottom: 8px;
}

.admincardbox .hicons a,
.admincardbox .hicons .btn {
  color: #fff;
}

.admincardbox .hicons .btn {
  padding: 0;
  border: 0px;
}

.bg-darkgreen {
  background-color: #033e30;
}
.bg-darkred {
  background-color: #ff4e4e;
}

.admincard .admincardbox .hovericons {
  position: absolute;
  top: 0px;
  bottom: 0;
  display: grid;
  place-content: center;
  z-index: 9;
  right: -40px;
  transition: all 0.3s;
}

.admincard .admincardbox:hover .hovericons {
  right: 10px;
}

.provider .formbox {
  max-width: 100%;
  padding: 0;
}

.provider .providerhead {
  background-color: #f8fffd;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  text-align: end;
}

.provider .providerhead a svg {
  font-size: 17px;
  position: relative;
  top: -1px;
}

.provider .providerbody .profileimg {
  width: 100px;
}

@media only screen and (min-width: 1600px) {
  .doctors {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (min-width: 1800px) {
  .doctors {
    grid-template-columns: repeat(5, 1fr);
  }
  .doctorsdetail .formbox {
    max-width: 1200px;
  }
  .reviewratings .appointments .formbox {
    max-width: 1000px;
  }
  .admincard {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media only screen and (991px <= width <= 1300px) {
  .appointments .flextime,
  .appointments .colorbox {
    gap: 7px;
  }
  .appointments .appointdetails .patientsbox p span {
    font-size: 13px;
  }
  .appointdetailsbox p,
  .appointdetailsbox p span {
    font-size: 15px;
  }
}
@media only screen and (576px <= width <= 991px) {
  .appointments .patientsbox p span {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1400px) {
  .appointments .patientsbox p {
    font-size: 15px;
  }
}
@media only screen and (max-width: 1300px) {
  .doctorsdetail .treatmentslist {
    column-count: 2;
  }
}
@media only screen and (max-width: 1200px) {
  .appointments.doctorreschedule,
  .doctorappointmentdetail .appointments,
  .doctorappointment .appointments,
  .reviewratings .appointments {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media only screen and (max-width: 1199px) {
  .doctors {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 991px) {
  .appointments {
    padding-bottom: 20px;
  }

  .appointments .patientsbox {
    flex-wrap: wrap;
    justify-content: center;
  }

  .appointments.appointmentsbox .patientsbox > div {
    width: 33.33%;
  }

  .appointments .colorbox {
    justify-content: center;
  }

  .appointdetailsbox .patientsappointment {
    flex-direction: column;
  }

  .appointdetailsbox .patientsappointment > div:first-child,
  .appointdetailsbox .patientsappointment > div:last-child {
    width: 100%;
  }

  .appointdetailsbox .patientsbox > div {
    margin-bottom: 35px;
    padding: 0px 12px;
  }

  .appointdetailsbox .profile {
    max-width: 250px;
    display: block;
    margin: auto;
  }
  .appointdetailsbox .patientsappointment > div:last-child {
    margin-top: 30px;
  }

  .appointdetailsbox .appointdetails {
    padding-bottom: 30px;
  }

  .appointmentsdate .formbox {
    max-width: 100%;
  }

  .appointmentsdate .datebox {
    flex-direction: column;
  }

  .doctorreschedule .patientsappointment {
    width: 100%;
  }
}

@media only screen and (576px <= width <= 991px) {
  .appointments.appointmentsbox .patientsbox > div:nth-child(n + 4) {
    margin-top: 25px;
    margin-bottom: 10px;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .appointmentsdate .formbox {
    padding: 14px;
  }

  .appointmentsdate .gridbox {
    grid-template-columns: repeat(4, 1fr);
  }
  .doctors {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media only screen and (max-width: 576px) {
  /* .doctors {
      grid-template-columns: repeat(1, 1fr);
  } */
  .appointments.appointmentsbox .patientsbox > div {
    width: 100%;
  }
  .appointments.appointmentsbox .patientsbox {
    gap: 25px;
    text-align: center;
  }

  .appointments.appointmentsbox .patientsbox > div:last-child {
    text-align: center;
  }

  .appointments .fleximg,
  .appointments .flextime {
    justify-content: center;
  }

  .appointments .flextime p {
    text-align: start;
  }

  .patientsappointment {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .appointments .nav-tabs {
    gap: 18px;
    justify-content: center;
  }

  .appointments {
    padding-top: 20px;
  }

  .appointments .h4 {
    font-size: 20px;
  }

  .appointdetailsbox .patientsbox > div {
    margin-bottom: 22px;
  }

  .appointdetailsbox .btn-violet {
    margin-right: 0;
    margin-bottom: 12px;
  }

  .formbox .flexbox {
    flex-direction: column;
    gap: 20px;
  }

  .appointments .formbox {
    margin-top: 30px;
  }
  .appointmentsdate .gridbox {
    grid-template-columns: repeat(2, 1fr);
  }

  .patientsappointment .react-calendar__navigation__label {
    font-size: 15px;
  }

  .patientsappointment .react-calendar__navigation {
    justify-content: space-between;
  }

  .doctors .flexbox {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .doctors .doctorimg {
    max-width: 100px;
  }

  .doctorsdetail .doctorimg {
    max-width: 90px;
  }

  .doctorsdetail .docprofile {
    margin-top: -20px;
    padding-left: 0;
  }
  .doctorsdetail .treatmentslist {
    column-count: 1;
  }

  .doctorsdetail .formbox {
    padding-left: 16px;
    padding-right: 16px;
  }
  .doctorsdetail .treatmentslist li:last-child {
    margin-bottom: 0;
  }
  .appointdetailsbox .appointdetails .flextime {
    justify-content: start;
  }
  .btn.btn-details {
    padding: 8px 22px;
  }

  .doctorreschedule .slotflex {
    flex-direction: row;
  }
  .doctorreschedule .accordion-body {
    padding: 16px 0px;
  }
  .doctorreschedule .slots .slottime {
    gap: 0px;
  }

  .doctorreschedule .slots:not(:last-child) {
    margin-bottom: 20px;
  }
  .reviewratings .greybox .flexbox {
    flex-direction: row;
  }
  .reviewratings .ratingimg {
    max-width: 70px;
  }
}

@media only screen and (max-width: 350px) {
  .form-check {
    align-items: center;
  }
}
