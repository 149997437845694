:root {
  --box_border: #2196f3;
  --green: #033e30;
  --button_border: #e5e5e5;
  --white: #fff;
  --black: #000;
  --secondary_black: #202020;
  --mild_black: #111111;
  --sky: #00abd2;
  --border_color: #00000029;
  --sky_secondary: #eefcff;
  --brown: #ae4f00;
  --brown_secondary: #feecde;
  --cal_green: #21ab70;
  --cal_greenSecondary: #edfdf6;
  --newSky: #4193ff;
  --newSky_secondary: #edf5ff;
  --violet: #560074;
  --violet_secondary: #f4d4ff;
  --extra_date: #f7f7f7;
  --notify_box: #e7e7e7;
  --patient_box: #2196f3;
  --care_box: #fd7e14;
  --violet_border: #6f42c1;
  --red_border: #ff4141;
  --reject_border: #ffecec;
  --reject_btn: #ff5151;
  --approve_btn: #007800;
  --approve_dull: #00780027;
  --gray: #8e97a5;
  --date_red: #ff4e44;
  --time_blue: #4849a6;
  --wait_btn: #6e94a9;
  --weight_four: 400;
  --weight_five: 500;
  --weight_six: 600;
  --weight_seven: 700;
  --weight_eight: 800;
  --weight_nine: 900;
  --small_font: 8px;
  --lower_fontsize: 10px;
  --font_eleven: 11px;
  --font_twelve: 12px;
  --font_thirteen: 13px;
  --font_fifteen: 15px;
  --font_sixteen: 16px;
  --font_eighteen: 18px;
  --font_twenty: 20px;
  --font_twentyfive: 25px;
  --higher_fontsize: 30px;
  --font_thirtyfive: 35px;
  --font_fourty: 40px;
  --font_fourtyfive: 45px;
  --font_fifty: 50px;
}
/* common css */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--green);
  border-radius: 10px;
}
.pointer {
  cursor: pointer !important;
}

/* end of common css */

/* dashboard css */
.dash__singleBox {
  min-height: 130px;
  height: 100%;
  cursor: pointer;
}
.dash__newSkyBox {
  border: 1px solid var(--newSky);
}
.dash__greenBox {
  border: 1px solid var(--cal_green);
}
.dash__orangeBox {
  border: 1px solid var(--care_box);
}
.dash__violetBox {
  border: 1px solid var(--violet_border);
}
.dash__redBox {
  border: 1px solid var(--red_border);
}
.dash__singleBoxLogos {
  height: 30px;
  width: 30px;
}
.dash__singleBoxLabel {
  color: var(--black);
  font-size: var(--font_twelve);
  font-weight: var(--weight_five);
}
.dash__singleBoxWhiteLabel {
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_five);
}
.dash__singleBoxValue {
  color: var(--black);
  font-size: var(--font_eighteen);
  font-weight: var(--weight_five);
}
.dash__singleBoxGraph {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}
.dash__lottieHolder {
  height: 80px;
  width: 80px;
}
.dash__lottieBox {
  background-color: var(--green);
  min-height: 130px;
  border: 1px solid var(--green);
  height: 100%;
}
/* end of dashboard css */

/* calendar css */
.cal__holderTab {
  height: 5px;
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.cal__skyHolder {
  background-color: var(--sky);
}
.cal__brownHolder {
  background-color: var(--brown);
}
.cal__newSkyHolder {
  background-color: var(--newSky);
}
.cal__violetHolder {
  background-color: var(--violet);
}

.calendar__holder .rbc-event,
.calendar__holder .cal__eventHolder {
  padding: 0px;
  background-color: transparent;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.calendar__holder .cal__skyEventHolder {
  background-color: var(--sky_secondary);
}
.calendar__holder .cal__brownEventHolder {
  background-color: var(--brown_secondary);
}
.calendar__holder .cal__newSkyEventHolder {
  background-color: var(--newSky_secondary);
}
.calendar__holder .cal__violeEventHolder {
  background-color: var(--violet_secondary);
}
.cal__rightBadge {
  height: 25px;
  width: 25px;
  padding: 3px 5px;
}
.cal__skyBadge {
  background-color: var(--sky);
}
.cal__brownBadge {
  background-color: var(--brown);
}
.cal__newSkyBadge {
  background-color: var(--newSky);
}
.cal__violetBadge {
  background-color: var(--violet);
}
.cal__rightBadge p {
  color: var(--white);
  font-size: var(--font_thirteen);
}
.cal__eventTitle {
  color: var(--black);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.cal__eventTime {
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
}
.cal__skyEventTime {
  color: var(--sky);
}
.cal__brownEventTime {
  color: var(--brown);
}
.cal__newSkyEventTime {
  color: var(--newSky);
}
.cal__violetEventTime {
  color: var(--violet);
}
.rbc-toolbar button,
.rbc-toolbar button:active,
.rbc-toolbar button:focus,
.rbc-toolbar button:focus-visible,
.rbc-toolbar button:hover {
  border: 1px solid var(--button_border) !important;
  margin-right: 10px !important;
  border-radius: 5px !important;
  padding: 10px 15px !important;
  font-size: var(--font_thirteen) !important;
  font-weight: var(--weight_five) !important;
}
.rbc-toolbar button.rbc-active {
  background-color: var(--green) !important;
  color: var(--white);
}
.rbc-toolbar button.rbc-active:hover {
  color: var(--white) !important;
}
.rbc-toolbar button:active,
.rbc-toolbar button:focus,
.rbc-toolbar button:focus-visible {
  color: var(--white) !important;
}
.rbc-date-cell button {
  font-size: var(--font_twelve) !important;
}
.calendar__holder .rbc-event.rbc-selected,
.calendar__holder .rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: transparent;
}
.calendar__holder .rbc-header {
  padding: 10px !important;
}
.calendar__holder .rbc-header span {
  font-weight: var(--weight_four) !important;
  font-size: var(--font_fifteen) !important;
  padding: 5px !important;
}
.calendar__holder .rbc-day-slot .rbc-event,
.calendar__holder .rbc-day-slot .rbc-background-event {
  border: none !important;
}
.calendar__holder.rbc-event {
  background-color: transparent;
}
.calendar__holder .rbc-off-range-bg {
  background: var(--extra_date) !important;
}
.calendar__holder .rbc-month-view {
  border-radius: 10px !important;
}
.rbc-addons-dnd.rbc-calendar {
  min-width: 900px;
}
.calendar__scoller {
  overflow-x: auto;
}
/* end of calendar css */

/* notificatin css */

.notify__holder {
  background-color: var(--notify_box);
  border: 1px solid var(--extra_date);
  max-height: 550px;
  overflow-y: auto;
}
.single__notify {
  border: 1px solid var(--extra_date);
}
.notify__title {
  color: var(--black);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.notify__img {
  height: 40px;
  width: 40px;
}
.notify__time {
  color: var(--black);
  font-size: var(--lower_fontsize);
  font-weight: var(--weight_five);
}
.notify__titler {
  color: var(--green);
  font-size: var(--font_twenty);
  font-weight: var(--weight_seven);
}
/* end of notification css */

/* doctor dashboard css */
.patientAppoint__title {
  color: var(--secondary_black);
  font-size: var(--font_eighteen);
  font-weight: var(--weight_seven);
}
.docDash__rejectBtn {
  border: 1px solid var(--reject_btn);
  outline: none;
  background-color: var(--reject_border);
  color: var(--reject_btn);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  padding: 5px 15px;
}
.docDash__rejectBtn:hover {
  background-color: var(--red_border);
  color: var(--white);
}
.docDash__acceptBtn {
  border: 1px solid var(--approve_btn);
  outline: none;
  background-color: var(--approve_btn);
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  padding: 5px 15px;
}
.docDash__acceptBtn:hover {
  background-color: var(--approve_dull);
  color: var(--approve_btn);
}
/* end of doctor dashboard css */

/* footer css */
.footer__linkAlign {
  color: var(--themeclr);
  text-decoration: none;
}
.footer__linkAlign:hover {
  color: var(--black);
}
/* end of footer css */

/* doctor waiting room css */
.pd__wholeShadowBox {
  background-color: var(--white);
  box-shadow: 0px 0px 4px 0px lightgray;
}
.pd__patientName {
  color: var(--black);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_six);
}
.pd__patientBadge {
  color: var(--mild_black);
  font-size: var(--font_twelve);
  font-weight: var(--weight_five);
}
.pd__patientTop {
  border-bottom: 1px solid var(--border_color);
}
.pd__grayLabel {
  color: var(--gray);
  font-size: var(--font_twelve);
  font-weight: var(--weight_five);
}
.pd__appointTitle {
  color: var(--black);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_six);
}
.pd__labelValue {
  color: var(--mild_black);
  font-size: var(--font_twelve);
  font-weight: var(--weight_five);
  min-width: 55%;
  max-width: 55%;
}
.pd__topDesc {
  color: var(--mild_black);
  font-size: var(--font_twelve);
  font-weight: var(--weight_five);
}
.pd__patientImg {
  max-height: 255px;
}
.pd__appointImg {
  height: 40px;
  width: 40px;
}
.pd__apointId.pd__appointValue {
  color: var(--care_box);
}
.pd__appointValue {
  color: var(--black);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.pd__apointId.pd__appointValue {
  color: var(--care_box);
}
.pd__apointDate.pd__appointValue {
  color: var(--date_red);
}
.pd__apointTime.pd__appointValue {
  color: var(--time_blue);
}
.pd__apointVisit.pd__appointValue {
  color: var(--box_border);
}
.pd__documentTitle {
  color: var(--black);
  font-size: var(--font_twelve);
  font-weight: var(--weight_five);
}
.pd__waitingBtnTxt {
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.pd__tipJson {
  height: 30px;
  width: 30px;
}
.pd__waitingBtn {
  border: none;
  outline: none;
  background-color: var(--wait_btn);
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.patientDetailsWhole {
  font-family: var(--poppins_font);
}
/* end of doctor waiting room css */

/* media query */
@media (width < 768px) {
  .rbc-toolbar {
    flex-direction: row !important;
  }
}
/* end of media query */
