@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --grayClr: #343434;
  --darkGrayclr: #202020;
  --darkGrayClr2: #161616;
  --darkGrayClr3: #2b2c2d;
  --darkGrayClr4: #444444;
  --lightGrayClr: #e7e7e7;
  --lightGrayClr2: #8e97a5;
  --lightGrayClr3: #f7f7f7;
  --lightGrayClr4: #7e7e89;
  --lightGrayClr5: #d9d9d9;
  --lightGrayClr6: #e1e1e1;
  --lightGrayClr7: #2a2a2a;
  --lightGrayClr8: #393939;
  --lightGrayClr9: #f0f0f0;
  --lightGrayClr10: #eff0f1;
  --lightGrayClr11: #b1b1b1;
  --lightGrayClr12: #474747;
  --lightGrayClr13: #707070;
  --whiteClr: #fff;
  --blackClr: #000;
  --blackClr2: #131313;
  --blackClr3: #111111;
  --blackClr4: #202124;
  --darkGreenClr: #033e30;
  --greenClr: #4caf50;
  --greenClr2: #edfdf6;
  --greenClr3: #d6f2ec;
  --greenClr4: #a8e0d3;
  --redClr: #ff0707;
  --redClr2: #ff5151;
  --redClr3: #ffecec;
  --redClr4: #f80d38;
  --redClr5: #ffe9ed;
  --redClr6: #ff2c2c;
  --blueClr: #2196f3;
  --green1: #e5f8f4;
  --poppins_font: "Poppins", sans-serif;
}
